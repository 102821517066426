'use client';

import Image from 'next/image';
import { Box, BoxContent, Link, Text, Title } from '@bytel/trilogy-react-ts';
import noProductImage from '@assets/no-product.png';
type EmptyProductListProps = {
  resetFilters?: () => void;
};
export function EmptyProductList({
  resetFilters
}: EmptyProductListProps) {
  return <Box data-sentry-element="Box" data-sentry-component="EmptyProductList" data-sentry-source-file="empty-product-list.tsx">
            <BoxContent className='has-text-centered' data-sentry-element="BoxContent" data-sentry-source-file="empty-product-list.tsx">
                <Image src={noProductImage} alt='' fetchPriority='high' width='294' height='176' data-sentry-element="Image" data-sentry-source-file="empty-product-list.tsx" />
                <Title className='center-title is-size-5' marginless data-sentry-element="Title" data-sentry-source-file="empty-product-list.tsx">
                    Oh, il semblerait qu&apos;aucun résultat ne correspond à votre recherche.
                </Title>
                <Text data-sentry-element="Text" data-sentry-source-file="empty-product-list.tsx">
                    Élargissez votre recherche en retirant des filtres ou{' '}
                    <Link onClick={() => resetFilters?.()} data-sentry-element="Link" data-sentry-source-file="empty-product-list.tsx">en les effaçant tous</Link>. Vous trouverez forcément votre
                    bonheur !
                </Text>
            </BoxContent>
        </Box>;
}