import { isServer } from '@helpers/config';

export function setSessionVariable(key: string, value: string) {
    if (isServer()) {
        return;
    }
    window.sessionStorage.setItem(key, value);
}

export function getSessionVariable(key: string) {
    if (isServer()) {
        return null;
    }
    return window.sessionStorage.getItem(key);
}

export function removeSessionVariable(key: string) {
    if (isServer()) {
        return;
    }
    window.sessionStorage.removeItem(key);
}

export function setLocalVariable(key: string, value: string) {
    if (isServer()) {
        return;
    }
    window.localStorage.setItem(key, value);
}

export function getLocalVariable(key: string) {
    if (isServer()) {
        return null;
    }
    return window.localStorage.getItem(key);
}

export function getLocalDataIfNotExpired<T>(key: string): T | null {
    const item = getLocalVariable(key);
    if (!item) return null; // Item not found

    const parsedItem = JSON.parse(item);

    if (parsedItem && typeof parsedItem === 'object' && !!parsedItem.expiresTimestamp) {
        const currentTime = Date.now();
        if (currentTime < parsedItem.expiresTimestamp) {
            return parsedItem.data;
        } else {
            // Item expired
            removeLocalVariable(key);
            return null;
        }
    } else {
        // No expiresTimestamp
        return parsedItem;
    }
}

export function removeLocalVariable(key: string) {
    window.localStorage.removeItem(key);
}
