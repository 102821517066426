import { Session } from 'next-auth';

import { roundPrices } from '@bytel/product-wall/helpers';

import { getCart } from '@services/cart';
import { getAppConfig } from '@services/config';

import { sapiRepository } from '@repositories/sapi';
import { GetProductOptionType, sapicRepository } from '@repositories/sapic';

import { SapiPlanType, SapiResponseType } from '@app-types/api/sapi';
import { SapicCategoryId, SapicFundingMode } from '@app-types/api/sapic';
import { CartProductType, QUOTE_TYPES } from '@app-types/cart';
import { ContractType } from '@app-types/contract';
import { DiscountType } from '@app-types/discount';
import { ObligationType, PlanDetailWithProvenanceType, PlanProvenanceType, PlanType } from '@app-types/plan';
import { SimulatorCart } from '@app-types/simulator/virtual-cart';

import { MarketingsState } from '@hooks/use-marketings';

import { mapApiPlanToPlanType } from '@helpers/product';

import { API_X_VERSION } from '@constants/api';
import { MarketingType, PlanTypeId } from '@constants/plan';

const appConfig = getAppConfig();

export function getDefaultPlan(isRenewal: boolean): number {
    return isRenewal ? appConfig.defaultPlans.renewal : appConfig.defaultPlans.acquisition;
}

export async function getCurrentPlan(
    searchParams?: URLSearchParams,
    planInCart?: PlanType,
    isRenewal: boolean = false,
    contract?: ContractType,
): Promise<PlanDetailWithProvenanceType> {
    const planInSearchParams = searchParams ? searchParams.get('forfait') : null;
    let origin: PlanDetailWithProvenanceType['origin'] = PlanProvenanceType.DEFAULT;
    let planDetails: PlanType;

    try {
        const ownedPlan =
            isRenewal && contract !== undefined ? await getPlanDetail(contract.subscription.offerId) : undefined;

        switch (true) {
            case ownedPlan && ownedPlan.isExcluPro:
                planDetails = ownedPlan;
                break;
            case planInSearchParams !== null:
                planDetails = await getPlanDetail(planInSearchParams);
                origin = PlanProvenanceType.URL;
                break;
            case planInCart !== undefined:
                planDetails = planInCart;
                origin = PlanProvenanceType.CART;
                break;
            case ownedPlan && contract!.subscription.isPremium:
                planDetails = ownedPlan;
                break;
            default:
                planDetails = await getPlanDetail(getDefaultPlan(isRenewal));
                break;
        }

        return { ...planDetails, origin };
    } catch (e) {
        return {
            gencode: getDefaultPlan(isRenewal).toString(),
            origin: PlanProvenanceType.DEFAULT,
        };
    }
}

export async function getPlanDetail(key: string | number): Promise<PlanType> {
    try {
        const data = await sapiRepository.getProductByGencode<SapiPlanType>(key.toString());
        return mapApiPlanToPlanType(data);
    } catch (e) {
        throw new Error(`Could not fetch ${key} data`);
    }
}

export const filterPromotionsByTypesMarketing = (
    promos: DiscountType[],
    types: MarketingType[],
    withIncitation: boolean = false,
    shouldInclude: boolean = true,
): DiscountType[] => {
    if (!promos || promos.length <= 0) {
        return [];
    }

    return promos.filter((promo) => {
        const isMarketingTypeIncluded = types.some((type) => promo.marketingTypes.includes(type));
        const isValidIncitation = withIncitation || promo.isIncitation === withIncitation;
        return shouldInclude
            ? isMarketingTypeIncluded && isValidIncitation
            : !isMarketingTypeIncluded && isValidIncitation;
    });
};

const getPromotionsAmount = (promos: DiscountType[]): number => {
    if (promos.length <= 0) {
        return 0;
    }
    return promos.reduce((acc, promotion) => acc + promotion.value, 0);
};

export type PlanPrice = {
    base: number;
    final: number;
    forever: number;
    promoDuration: number;
};

export const getPlanPrice = (withTypeMarketing: MarketingsState, plan?: PlanType): PlanPrice => {
    const price = {
        base: plan?.details.price.initial ?? 0,
        final: plan?.details.price.initial ?? 0,
        forever: plan?.details.price.initial ?? 0,
        duration: 0,
    };

    const promos = plan?.details?.discounts ?? [];

    const promosPorta = filterPromotionsByTypesMarketing(promos, [MarketingType.PORTABILITY], true, true);
    const promosHome = filterPromotionsByTypesMarketing(promos, [MarketingType.HOME], false, true);
    const promosNotConverNotPortaNotHome = filterPromotionsByTypesMarketing(
        promos,
        [MarketingType.PORTABILITY, MarketingType.HOME],
        true,
        false,
    );

    if (withTypeMarketing[MarketingType.PORTABILITY]) {
        price.final -= getPromotionsAmount(promosPorta);
    }

    if (withTypeMarketing[MarketingType.HOME]) {
        price.forever -= getPromotionsAmount(promosHome);
        price.final -= getPromotionsAmount(promosHome);
    }

    if (promosNotConverNotPortaNotHome && promosNotConverNotPortaNotHome.length > 0) {
        price.final -= getPromotionsAmount(promosNotConverNotPortaNotHome);
        price.base -= getPromotionsAmount(promosNotConverNotPortaNotHome);
        promosNotConverNotPortaNotHome.forEach((promo) => {
            if (promo.duration === 0) {
                price.forever -= getPromotionsAmount([promo]);
            }
        });
    }

    price.duration = Math.max(...promos.map((promo) => promo.duration));

    if (price.final.toFixed(2) === price.forever.toFixed(2)) {
        price.duration = 0;
    }

    return {
        base: roundPrices(price.base),
        final: roundPrices(price.final),
        forever: roundPrices(price.forever),
        promoDuration: price.duration,
    };
};

function hasObligation(plan: CartProductType): boolean {
    const obligation = plan.data.obligation; // ou obligation_id ???
    return !!(obligation && !ObligationType.NONE);
}

function getPlanTypeFromPlanInCart(planInCart: CartProductType): PlanTypeId | undefined {
    const defaultPlans = [
        PlanTypeId.SIMO,
        PlanTypeId.SOWO,
        PlanTypeId.PREMIUM,
        PlanTypeId.FAIM,
        // FaimSensation.PLAN_TYPE, ===> ???
    ];

    let planType: PlanTypeId;

    if (planInCart) {
        planType = planInCart.data.type_id as PlanTypeId;
        if (planType === PlanTypeId.SOWO) {
            planType = hasObligation(planInCart) ? PlanTypeId.SIMO : PlanTypeId.SOWO;
        }
        if (!defaultPlans.includes(planType)) {
            return;
        }
        return planType;
    }
}

export function getPlanType(forfaitInUrl?: string, isRenewal?: boolean): PlanTypeId {
    let planType = getPlanTypeFromUrl(forfaitInUrl) || PlanTypeId.PREMIUM;
    const cart = getCart(isRenewal);
    const planInCart = cart?.products.find((product) =>
        product.categories.some((category) => category.includes('plan')),
    );

    if (!forfaitInUrl && planInCart) {
        const planInCartTypeId = getPlanTypeFromPlanInCart(planInCart);
        const wallPlans = [PlanTypeId.PREMIUM, PlanTypeId.SIMO, PlanTypeId.SOWO];

        if (
            planInCartTypeId &&
            wallPlans.includes(planInCartTypeId) &&
            planInCart.categories.includes(PlanTypeId.SIMO)
        ) {
            planType = PlanTypeId.SIMO;
        }
    }

    if (planType === PlanTypeId.SOWO) {
        planType = PlanTypeId.SOWO_SPECIALS;
    }

    return planType;
}

export function getPlanTypeFromUrl(forfaitInUrl?: string): PlanTypeId | undefined {
    if (forfaitInUrl?.includes('smartphone')) {
        return PlanTypeId.PREMIUM;
    } else if (forfaitInUrl?.includes('sim-seule')) {
        return PlanTypeId.SIMO;
    } else if (forfaitInUrl?.includes('b-you')) {
        return PlanTypeId.SOWO_SPECIALS;
    }
}

export const getVirtualCart = (
    productGencode: string | undefined,
    isRenewal: boolean,
    contractId?: string,
    clientBbox?: boolean,
    shouldAddMobileJourney = true,
): SimulatorCart => {
    const virtualCart: SimulatorCart = {
        cart: {
            quotes: [
                {
                    type: isRenewal ? QUOTE_TYPES.RENEWAL : QUOTE_TYPES.ACQUISITION,
                    isCurrent: true,
                    products: productGencode ? [{ gencode: productGencode }] : [],
                    contractId,
                },
            ],
        },
    };

    const genericBboxGencode = appConfig.defaultPlans?.simulator?.fai;
    const genericPlanGencode = appConfig.defaultPlans?.simulator?.mobile;

    const addQuoteToCart = (type: QUOTE_TYPES, gencode: string) => {
        virtualCart.cart.quotes.push({
            type,
            isCurrent: false,
            products: [{ gencode }],
            contractId,
        });
    };

    if (!isRenewal && clientBbox && genericBboxGencode && genericPlanGencode) {
        // we want to show the prices for an existing customer of bbox + mobile
        addQuoteToCart(QUOTE_TYPES.ACQUISITION_FIXE_V4, genericBboxGencode);
    }

    if (genericPlanGencode && shouldAddMobileJourney) {
        addQuoteToCart(QUOTE_TYPES.ACQUISITION, genericPlanGencode);
    }

    return virtualCart;
};

export const getPlans = async (
    productGencode: string | undefined,
    isRenewal: boolean,
    categorie: PlanTypeId,
    contractId?: string,
    planGencode?: string,
    session?: Session,
    isClientBbox?: boolean,
    shouldAddMobileJourney: boolean = true,
): Promise<PlanType[]> => {
    const virtualCart = getVirtualCart(productGencode, isRenewal, contractId, isClientBbox, shouldAddMobileJourney);
    const category = categorie ? (categorie as unknown as SapicCategoryId) : undefined;
    const planParams: GetProductOptionType = {
        category,
        limit: 50,
        gencodes: undefined,
        fundingMode: SapicFundingMode.MIN_ONE_OFF,
        withDetails: true,
    };

    let plans = await sapicRepository.getPlans(virtualCart, planParams, session, API_X_VERSION.V4).then((response) => {
        return response.products;
    });

    // si le plan au panier n'est pas de la même catégorie que la catégorie actuelle
    if (planGencode && !plans.find((plan) => plan.gencode === planGencode)) {
        const planCartParams: GetProductOptionType = {
            gencodes: [planGencode],
            fundingMode: SapicFundingMode.MIN_ONE_OFF,
            withDetails: true,
        };

        const cartPlan = await sapicRepository
            .getPlans(virtualCart, planCartParams, session, API_X_VERSION.V4)
            .then((response) => {
                return response.products;
            });

        plans = [...plans, ...cartPlan];
    }

    if (isRenewal) {
        plans = plans.filter((plan) => planGencode?.split(',').includes(plan.gencode));
    }

    return await transformToPlanTypeList(plans, categorie, productGencode);
};

const transformToPlanTypeList = async (
    plans: PlanType[],
    category: PlanTypeId,
    gencode?: string,
): Promise<PlanType[]> => {
    if (!plans || plans.length === 0) {
        return [];
    }

    const searchParams = new URLSearchParams({
        categorie: category,
        listGencode: plans.map((plan) => plan.gencode).join(','),
    });

    const result = await sapiRepository.getProducts<SapiResponseType<SapiPlanType>>(`?${searchParams}`);

    const sortedPlans = Object.values(result.produits).sort((a, b) => a.data_size - b.data_size);

    return sortedPlans.map((product) => {
        const plan = plans.find((p) => p.gencode === product.gencode)!;
        return mapApiPlanToPlanType(product, plan, gencode);
    });
};
