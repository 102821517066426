'use client';

import { calculatePrice } from '@bytel/product-wall/helpers';
import { Alignable, Columns, ColumnsItem, Divider, Icon, IconName, Popover, Text, TextLevels, TypographyBold, View } from '@bytel/trilogy-react-ts';
import { Price } from '@components/price';
type PriceDetailProps = {
  value: number;
  isTaxExcluded?: boolean;
};
export function PriceDetailToday({
  value,
  isTaxExcluded = false
}: PriceDetailProps) {
  return <Columns mobile data-sentry-element="Columns" data-sentry-component="PriceDetailToday" data-sentry-source-file="price-detail-today.tsx">
            <ColumnsItem size={9} verticalCenter className='has-text-left' data-sentry-element="ColumnsItem" data-sentry-source-file="price-detail-today.tsx">
                <View flexable data-sentry-element="View" data-sentry-source-file="price-detail-today.tsx">
                    <Text marginless data-sentry-element="Text" data-sentry-source-file="price-detail-today.tsx">Remboursement après achat</Text>
                    <Popover content='Le coupon et les instructions vous seront envoyés par mail après achat.' data-sentry-element="Popover" data-sentry-source-file="price-detail-today.tsx">
                        <Icon name={IconName.INFOS_CIRCLE} className='popover-modal' data-sentry-element="Icon" data-sentry-source-file="price-detail-today.tsx" />
                    </Popover>
                </View>
            </ColumnsItem>
            <ColumnsItem size={3} className='has-text-right' data-sentry-element="ColumnsItem" data-sentry-source-file="price-detail-today.tsx">
                <Price align={Alignable.ALIGNED_END} amount={calculatePrice(-(value < 0 ? 1 : value), isTaxExcluded)} mention={isTaxExcluded ? 'HT' : ''} className='font-inherit' data-sentry-element="Price" data-sentry-source-file="price-detail-today.tsx" />
            </ColumnsItem>
        </Columns>;
}
export function PriceTotalToday({
  value,
  isTaxExcluded = false
}: PriceDetailProps) {
  return <>
            <Divider data-sentry-element="Divider" data-sentry-source-file="price-detail-today.tsx" />
            <Columns mobile data-sentry-element="Columns" data-sentry-source-file="price-detail-today.tsx">
                <ColumnsItem size={8} verticalCenter className='has-text-left' data-sentry-element="ColumnsItem" data-sentry-source-file="price-detail-today.tsx">
                    <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD} data-sentry-element="Text" data-sentry-source-file="price-detail-today.tsx">
                        Prix après remboursement
                    </Text>
                </ColumnsItem>
                <ColumnsItem size={4} className='has-text-right' data-sentry-element="ColumnsItem" data-sentry-source-file="price-detail-today.tsx">
                    =&nbsp;
                    <Price className='is-size-5 has-text-weight-semibold' level={3} amount={calculatePrice(value, isTaxExcluded)} mention={isTaxExcluded ? 'HT' : ''} data-sentry-element="Price" data-sentry-source-file="price-detail-today.tsx" />
                </ColumnsItem>
            </Columns>
        </>;
}