export function disableTlsVerification() {
    process.emitWarning = (
        (originalEmitWarning) =>
        (warning, ...args) => {
            if (typeof warning === 'string' && warning.includes('NODE_TLS_REJECT_UNAUTHORIZED')) {
                return;
            }
            // @ts-expect-error — TS doesn't know about the originalEmitWarning function
            return originalEmitWarning(warning, ...args);
        }
    )(process.emitWarning);
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}
