export enum LegalType {
    WALL = 'telephones-mobiles',
    WALL_RENEWAL = 'forfaits-mobiles_avec-engagement-24-mois_renouvellement',
    PHONE_PRODUCT = 'telephones-mobiles_produit',
    ACCESSORY_PRODUCT = 'accessoires_produit',
    POST_ELIG = 'offres-internet_test-eligibilite',
    FAI_HOME = 'offres-internet',
    BIG_HOME = 'offres-box-forfait',
    PACK = 'offres-internet_combo-box',
    OFFER_WALL = 'partials_mobiles_sas_contenu',
}
