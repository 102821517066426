import { transporter } from '@services/loggers/http-transporter.service';
import { extractUrlPattern } from '@services/loggers/patterns';

import { HttpLoggerData, LogHttpTransporterInterface } from '@app-types/logger';

type OAuthCookie = {
    name: string;
    value: string;
    options: Record<string, unknown>;
};

type OAuthData = {
    url: URL;
    cookies: OAuthCookie[];
};

class OauthLoggerService {
    private transporter: LogHttpTransporterInterface;

    public constructor(transporter: LogHttpTransporterInterface) {
        this.transporter = transporter;
    }

    public log(code: string, metadata: unknown) {
        switch (code) {
            case 'authorization url is ready':
                const config = metadata as OAuthData;
                const httpData: Partial<HttpLoggerData> = {};
                httpData.uri = config.url.href ?? '';
                httpData.method = 'GET';
                httpData.typeEv = 'APPELRESS';
                httpData.urlPattern = config.url ? extractUrlPattern(config.url.href) : '';
                this.transporter.send(httpData as HttpLoggerData);
                break;
            default:
                break;
        }
    }
}

export const oauthLoggerService = new OauthLoggerService(transporter);
