'use client';

import { useEffect } from 'react';
import { Alert, Box, Columns, ColumnsItem, Container, Headline, HeadlineLevel, Icon, IconName, IconSize, Link, Section, Text, TextLevels, Title, TitleLevels, TitleMarkup, TrilogyColor, TypographyBold } from '@bytel/trilogy-react-ts';
import { paths } from '@helpers/path';
import { sendPageViewTracking } from '@helpers/tracking';
function NotFoundHeader() {
  return <Section background={TrilogyColor.PATTERN_DARK} className='has-text-centered' data-sentry-element="Section" data-sentry-component="NotFoundHeader" data-sentry-source-file="not-found.tsx">
            <Container fluid data-sentry-element="Container" data-sentry-source-file="not-found.tsx">
                <Icon name={IconName.INTERNAL_FRAUD} size={IconSize.HUGE} color={TrilogyColor.WHITE} data-sentry-element="Icon" data-sentry-source-file="not-found.tsx" />
                <Headline level={HeadlineLevel.LEVEL1} markup={TitleMarkup.H1} inverted className='is-marginless' data-sentry-element="Headline" data-sentry-source-file="not-found.tsx">
                    Cette page est introuvable
                </Headline>
                <Title level={TitleLevels.TWO} markup={TitleMarkup.H2} inverted data-sentry-element="Title" data-sentry-source-file="not-found.tsx">
                    Il est possible qu&apos;elle ait été supprimée, ou déplacée.
                </Title>
                <Title level={TitleLevels.TWO} markup={TitleMarkup.H2} inverted data-sentry-element="Title" data-sentry-source-file="not-found.tsx">
                    Pas de panique, on vous aide à retrouver votre chemin.
                </Title>
            </Container>
        </Section>;
}
function NotFoundContent() {
  return <Section background={TrilogyColor.GREY_LIGHTER} data-sentry-element="Section" data-sentry-component="NotFoundContent" data-sentry-source-file="not-found.tsx">
            <Container fluid data-sentry-element="Container" data-sentry-source-file="not-found.tsx">
                <Columns data-sentry-element="Columns" data-sentry-source-file="not-found.tsx">
                    <ColumnsItem size={5} data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                        <Headline level={HeadlineLevel.LEVEL3} markup={TitleMarkup.H2} data-sentry-element="Headline" data-sentry-source-file="not-found.tsx">
                            De quoi avez-vous besoin ?
                        </Headline>
                        <Text level={TextLevels.ONE} marginless data-sentry-element="Text" data-sentry-source-file="not-found.tsx">
                            Téléphone, forfait mobile, abonnement internet, assistance...
                        </Text>
                        <Text level={TextLevels.ONE} data-sentry-element="Text" data-sentry-source-file="not-found.tsx">On a forcément quelque chose pour vous.</Text>
                    </ColumnsItem>
                    <ColumnsItem size={7} data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                        <Columns multiline data-sentry-element="Columns" data-sentry-source-file="not-found.tsx">
                            <ColumnsItem size={6} data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                                <InfoBox title='Forfaits mobile' description="Avec ou sans smartphone, promis, on a le forfait qu'il vous faut&nbsp;!" linkText='Découvrir nos offres' linkHref={paths.plans} data-sentry-element="InfoBox" data-sentry-source-file="not-found.tsx" />
                            </ColumnsItem>
                            <ColumnsItem size={6} data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                                <InfoBox title='Box internet' description='Fibre, ADSL, 4G box : à chacun sa solution.' linkText='Tester votre éligibilité' linkHref={paths.homeFai} data-sentry-element="InfoBox" data-sentry-source-file="not-found.tsx" />
                            </ColumnsItem>
                            <ColumnsItem size={6} data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                                <InfoBox title='Assistance' description='Besoin de nous contacter pour une question technique ou commerciale&nbsp;?' linkText='Obtenir une réponse' linkHref='https://www.assistance.bouyguestelecom.fr/s/' data-sentry-element="InfoBox" data-sentry-source-file="not-found.tsx" />
                            </ColumnsItem>
                            <ColumnsItem size={6} data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                                <InfoBox title='Espace client' description='Suivre votre conso, accéder à votre facture, modifier votre offre...' linkText='Se connecter' linkHref={paths.clientSpace} data-sentry-element="InfoBox" data-sentry-source-file="not-found.tsx" />
                            </ColumnsItem>
                            <ColumnsItem data-sentry-element="ColumnsItem" data-sentry-source-file="not-found.tsx">
                                <Alert description={<Text level={TextLevels.ONE}>
                                            <span>Vous pouvez aussi revenir sur </span>
                                            <Link href={paths.index}>la page d&apos;accueil</Link>
                                            <span> ou utiliser le menu situé en haut de page</span>
                                        </Text>} className='is-fullwidth font-inherit' display iconName={IconName.LIGHTBULB_ON} data-sentry-element="Alert" data-sentry-source-file="not-found.tsx" />
                            </ColumnsItem>
                        </Columns>
                    </ColumnsItem>
                </Columns>
            </Container>
        </Section>;
}
interface InfoBoxProps {
  title: string;
  description: string;
  linkText: string;
  linkHref: string;
}
function InfoBox({
  title,
  description,
  linkText,
  linkHref
}: Readonly<InfoBoxProps>) {
  return <Box fullheight className='is-spaced-between' data-sentry-element="Box" data-sentry-component="InfoBox" data-sentry-source-file="not-found.tsx">
            <div>
                <Text level={TextLevels.TWO} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD} data-sentry-element="Text" data-sentry-source-file="not-found.tsx">
                    {title}
                </Text>
                <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="not-found.tsx">{description}</Text>
            </div>
            <Link href={linkHref} data-sentry-element="Link" data-sentry-source-file="not-found.tsx">{linkText}</Link>
        </Box>;
}
export function NotFound() {
  useEffect(() => {
    sendPageViewTracking(false, {
      page: 'Erreur_404',
      pageCategory: 'Erreur',
      universe: 'Boutique_web'
    });
  }, []);
  return <>
            <NotFoundHeader data-sentry-element="NotFoundHeader" data-sentry-source-file="not-found.tsx" />
            <NotFoundContent data-sentry-element="NotFoundContent" data-sentry-source-file="not-found.tsx" />
        </>;
}