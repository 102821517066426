import { PUBLIC_ENV_KEY } from 'next-runtime-env';

import { isServer } from '@helpers/config';

// Surcharge env from next-env-runtime that don't work since next 15 because of unstable_noStore

export function env(key: string): string | undefined {
    if (!isServer()) {
        if (!key.startsWith('NEXT_PUBLIC_')) {
            throw new Error(`Environment variable '${key}' is not public and cannot be accessed in the browser.`);
        }

        return window[PUBLIC_ENV_KEY][key];
    }

    return process.env[key];
}
