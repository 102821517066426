import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS, HttpService } from '@services/http/http';

import { StaticBannerAnimcoApi } from '@app-types/animco';
import { PalpatineProduct, PalpatineStickerItemType, PalpatineStickerType } from '@app-types/api/palpatine';
import { StaticCategoryApiResponseBase, StaticProductsAPI, StaticTabsResponse } from '@app-types/api/statics';

const appConfig = getAppConfig();

export class CmsService {
    private httpService: HttpService;

    public constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getProduct(): Promise<{ items: PalpatineProduct[] }> {
        return this.httpService
            .get<{ items: PalpatineProduct[] }>(`${appConfig.assets.palpatine.product}/anim_fiche_produit`, undefined, {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            })
            .catch((error) => {
                console.error('Error while fetching palpatine product', error);
                return { items: [] };
            });
    }

    public async getRocProducts() {
        return this.httpService.get<StaticProductsAPI>(
            `${appConfig.assets.palpatine.fai}/fai_roc_products`,
            undefined,
            {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public async getRocTabs() {
        return this.httpService.get<StaticTabsResponse>(`${appConfig.assets.palpatine.fai}/fai_roc_tabs`, undefined, {
            ttl: parseInt(appConfig.cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        });
    }

    public async getPack() {
        return this.httpService.get<StaticCategoryApiResponseBase>(
            `${appConfig.assets.palpatine.fai}/fai_samsung_page_pack`,
            undefined,
            {
                ttl: 0,
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public async getStickers<T extends PalpatineStickerItemType>(url: string) {
        return this.httpService.get<PalpatineStickerType<T>>(
            `${appConfig.assets.palpatine.sticker}/banners_location/${url}`,
            {
                findByKey: 'slug',
            },
            {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public getBanner(url: string) {
        return this.httpService
            .get<StaticBannerAnimcoApi>(
                `${appConfig.assets.palpatine.banners}/landing_pages_bandeau_emplacement/${url}`,
                {
                    findByKey: 'slug',
                },
                {
                    ttl: parseInt(appConfig.cache.ttl.cms),
                    tags: [CACHE_TAGS.CMS],
                },
            )
            .catch((error) => {
                console.error('Error while fetching palpatine banner', error);
                return undefined;
            });
    }
}

export const cmsService = new CmsService(cmsHttpService);
