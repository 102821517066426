'use client';

import { ImageLoaderProps } from 'next/dist/shared/lib/image-config';
import Image from 'next/image';
import { CSSProperties } from 'react';
import { classNames } from '@bytel/product-wall/helpers';
import { getAppConfig } from '@services/config';
export const POSITION_PRIORITY = 6;
export type ImageProps = {
  src: string;
  alt: string;
  width: number;
  height: number;
  style?: CSSProperties;
  position?: number;
  id?: string;
  className?: string;
};
export default function thumborLoader({
  src
}: ImageLoaderProps) {
  const config = getAppConfig();
  if (config.thumbor.active) {
    return `${config.thumbor.url}/full${src}`;
  }
  return `${config.assets.images.url}${src}`;
}
export function ImageThumbor({
  position = 99,
  alt,
  ...props
}: ImageProps) {
  return <div className='image is-flex is-centered' data-sentry-component="ImageThumbor" data-sentry-source-file="image-thumbor.tsx">
            <Image priority={position < POSITION_PRIORITY} loader={thumborLoader} alt={alt} {...props} data-sentry-element="Image" data-sentry-source-file="image-thumbor.tsx" />
        </div>;
}
export function AccessoryImageThumbor({
  className = '',
  ...props
}: ImageProps) {
  return <ImageThumbor className={classNames(className, 'accessory-image')} {...props} data-sentry-element="ImageThumbor" data-sentry-component="AccessoryImageThumbor" data-sentry-source-file="image-thumbor.tsx" />;
}