import { disableTlsVerification } from '@helpers/disable-tls-verification';

import { axiosInstanceFactory } from './axios-instance-factory';
import { AxiosService } from './axios-service';

disableTlsVerification();

export const httpService = new AxiosService(axiosInstanceFactory(false), false);
export const externalHttpService = new AxiosService(axiosInstanceFactory(true));

export const apiHttpService = new AxiosService(axiosInstanceFactory(false), true);
export const cmsHttpService = new AxiosService(axiosInstanceFactory(false), false, true);
