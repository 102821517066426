'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { Columns, ColumnsItem, Icon, IconName, IconPosition, IconSize, Modal, ModalTitle, Text } from '@bytel/trilogy-react-ts';
export interface RedirectionOptionsType {
  acquisition: {
    url: string;
    icon?: IconName;
    cta?: string;
  };
  renewal: {
    url?: string;
    icon?: IconName;
    cta?: string;
  };
}
interface ProductRedirectModalProps {
  options: RedirectionOptionsType;
  onAction?: (shouldRedirect?: boolean) => void;
}
export function ProductRedirectModal({
  options,
  onAction
}: Readonly<ProductRedirectModalProps>) {
  const router = useRouter();
  const onExit = () => {
    onAction?.();
    router.push(options.acquisition.url);
  };
  return <Modal active closeIcon onClose={onExit} className='z-level-10' data-sentry-element="Modal" data-sentry-component="ProductRedirectModal" data-sentry-source-file="product-redirect-modal.tsx">
            <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="product-redirect-modal.tsx">Êtes-vous nouveau ou déjà client ?</ModalTitle>
            <Columns marginless data-sentry-element="Columns" data-sentry-source-file="product-redirect-modal.tsx">
                <ColumnsItem data-sentry-element="ColumnsItem" data-sentry-source-file="product-redirect-modal.tsx">
                    <Icon name={options.acquisition.icon ?? IconName.MOBILE} size={IconSize.LARGE} position={IconPosition.UP} content='Nouveau client' textClassName='is-size-4 has-text-weight-semibold' data-sentry-element="Icon" data-sentry-source-file="product-redirect-modal.tsx" />
                    <Text className='is-size-6 has-text-weight-light' data-sentry-element="Text" data-sentry-source-file="product-redirect-modal.tsx">&nbsp;</Text>
                    <Link className='button is-primary' onClick={() => onAction?.()} href={options.acquisition.url} data-cy='prospect-client' data-sentry-element="Link" data-sentry-source-file="product-redirect-modal.tsx">
                        {options.acquisition.cta ?? 'Continuer ma visite'}
                    </Link>
                </ColumnsItem>
                <ColumnsItem className='has-background-grey-lighter' data-sentry-element="ColumnsItem" data-sentry-source-file="product-redirect-modal.tsx">
                    <Icon name={options.renewal.icon ?? IconName.MOBILE_UP_DOWN} size={IconSize.LARGE} position={IconPosition.UP} content='Déjà client' textClassName='is-size-4 has-text-weight-semibold' data-sentry-element="Icon" data-sentry-source-file="product-redirect-modal.tsx" />
                    <Text className='is-size-6 has-text-weight-light' data-sentry-element="Text" data-sentry-source-file="product-redirect-modal.tsx">Profitez de prix personnalisés !</Text>
                    <Link className='button is-secondary' onClick={() => onAction?.(true)} href={options.renewal.url ?? ''} data-cy='renewal-client' data-sentry-element="Link" data-sentry-source-file="product-redirect-modal.tsx">
                        {options.renewal.cta ?? 'Changer de téléphone'}
                    </Link>
                </ColumnsItem>
            </Columns>
        </Modal>;
}