'use client';

import { ReactNode } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Container, TrilogyColor } from '@bytel/trilogy-react-ts';
import { SSIBlock } from '@components/ssi-block';
type LegalsProps = {
  id: string;
  url: string;
};
function LegalsContainer({
  id,
  children
}: {
  id: string;
  children: ReactNode;
}) {
  return <Container fluid background={TrilogyColor.WHITE} data-cy='legals' data-sentry-element="Container" data-sentry-component="LegalsContainer" data-sentry-source-file="legals.tsx">
            <Accordion data-sentry-element="Accordion" data-sentry-source-file="legals.tsx">
                <AccordionItem id={`legals-${id}`} data-sentry-element="AccordionItem" data-sentry-source-file="legals.tsx">
                    <AccordionHeader toggle data-sentry-element="AccordionHeader" data-sentry-source-file="legals.tsx">Mentions légales</AccordionHeader>
                    <AccordionBody data-sentry-element="AccordionBody" data-sentry-source-file="legals.tsx">{children}</AccordionBody>
                </AccordionItem>
            </Accordion>
        </Container>;
}
export function Legals({
  id,
  url
}: LegalsProps) {
  if (!url) {
    return null;
  }
  return <SSIBlock tagId={id} url={`/partials/mentions_legales/emplacements/${url}`} container={LegalsContainer} containerProps={{
    id
  }} data-sentry-element="SSIBlock" data-sentry-component="Legals" data-sentry-source-file="legals.tsx" />;
}