'use client';

import { SessionProvider, SessionProviderProps } from 'next-auth/react';
import React, { useEffect } from 'react';
import { TrilogyProviderStyled } from '@bytel/trilogy-react-ts/lib/context/providerStyled';
import { signIn } from '@helpers/authentication';
import { getClientCookies } from '@helpers/cookies-client';
import { isSessionExpired } from '@helpers/session';
import * as Sentry from '@sentry/nextjs';
export function Provider({
  children
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    Sentry.getCurrentScope().setUser({
      id: getClientCookies('x-tracker-id')
    });
  }, []);
  return <TrilogyProviderStyled data-sentry-element="TrilogyProviderStyled" data-sentry-component="Provider" data-sentry-source-file="index.tsx">{children}</TrilogyProviderStyled>;
}
export function NextAuthProvider({
  children,
  session
}: SessionProviderProps) {
  useEffect(() => {
    if (isSessionExpired(session)) {
      document.cookie = 'next-session-token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      void signIn();
    }
  }, [session?.user?.sub, session?.user?.exp]);
  return <SessionProvider session={session} basePath='/webapi/auth' data-sentry-element="SessionProvider" data-sentry-component="NextAuthProvider" data-sentry-source-file="index.tsx">
            {children}
        </SessionProvider>;
}