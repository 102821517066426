import { Oauth2UserType } from '@app-types/oauth2';

import { JWT } from '@auth/core/jwt';

export type UserType = {
    accessToken: string;
} & Oauth2UserType &
    JWT;

export enum UserTypeEnum {
    CDC = 'CDC',
    CDV = 'CDV',
}

export const ForbiddenUserType = [UserTypeEnum.CDC, UserTypeEnum.CDV];
