import { env } from '@helpers/env';

export function generateConfig() {
    return {
        siteURl: env('NEXT_PUBLIC_SITE_URL') ?? '',
        bench: env('NEXT_PUBLIC_BENCH') ?? '',
        enableAnalytics: env('NEXT_PUBLIC_ENABLE_ANALYTICS') === 'true',
        cart: env('NEXT_PUBLIC_CART_ENV') as 'PROD' | 'AP1' | 'AP2' | 'AP3' | 'AP4',
        mocks: {
            mcube: process.env.MCUBE_MOCK ? process.env.MCUBE_MOCK === 'true' : undefined,
        },
        sapi: {
            url: process.env.SAPI_URL ?? env('NEXT_PUBLIC_FRONT_API_URL') ?? '',
        },
        sapic: {
            url: process.env.SAPIC_URL ?? env('NEXT_PUBLIC_FRONT_API_URL') ?? '',
        },
        api: {
            url: process.env.API_URL ?? env('NEXT_PUBLIC_API_URL') ?? '',
        },
        b2r: {
            url: process.env.B2R_URL ?? env('NEXT_PUBLIC_B2R_URL') ?? '',
        },
        contrats: {
            url: process.env.CONTRATS_URL ?? env('NEXT_PUBLIC_CONTRATS_URL') ?? '',
        },
        productAlert: {
            url: env('NEXT_PUBLIC_PRODUCT_ALERT_URL') ?? '',
        },
        proxy: {
            url: process.env.PROXY_URL,
        },
        skeepers: {
            url: process.env.SKEEPERS_URL ?? '',
            id: process.env.SKEEPERS_ID ?? '',
        },
        smartTraffik: {
            url: env('NEXT_PUBLIC_SMART_TRAFFIK_URL'),
            apikey: env('NEXT_PUBLIC_SMART_TRAFFIK_APIKEY') ?? '',
        },
        numeriques: {
            url: env('NEXT_PUBLIC_NUMERIQUES_URL') ?? '',
            clientSecret: env('NEXT_PUBLIC_NUMERIQUES_CLIENT_SECRET') ?? '',
            clientId: env('NEXT_PUBLIC_NUMERIQUES_CLIENT_ID') ?? '',
        },
        eresa: {
            locator: env('NEXT_PUBLIC_ERESA_LOCATOR') ?? '',
            preorder: env('NEXT_PUBLIC_ERESA_PREORDER') ?? '',
        },
        prisme: {
            url: process.env.NEXT_PRISME_URL ?? env('NEXT_PUBLIC_PRISME_URL') ?? '',
        },
        oauth2: {
            clientId: env('NEXT_PUBLIC_OAUTH2_CLIENT_ID') ?? '',
            scope: env('NEXT_PUBLIC_OAUTH2_SCOPE') ?? '',
            url: env('NEXT_PUBLIC_OAUTH2_URL') ?? '',
            authorizationUrl: env('NEXT_PUBLIC_OAUTH2_AUTHORIZATION_URL') ?? '',
            tokenUrl: process.env.OAUTH2_TOKEN_URL,
            userInfoUrl: process.env.OAUTH2_USERINFO_URL,
        },
        graphQL: {
            url: process.env.GRAPHQL_URL ?? env('NEXT_PUBLIC_GRAPHQL_URL') ?? '',
            source: env('NEXT_PUBLIC_GRAPHQL_SOURCE') ?? '',
            bannerScope: env('NEXT_PUBLIC_BANNER_SCOPE') ?? '',
            careScope: env('NEXT_PUBLIC_CARE_SCOPE') ?? '',
            animcoProduct: env('NEXT_PUBLIC_ANIMCO_PRODUCT') ?? '',
        },
        defaultPlans: {
            acquisition: parseInt(env('NEXT_PUBLIC_DEFAULT_PLAN') ?? ''),
            renewal: parseInt(env('NEXT_PUBLIC_DEFAULT_RENEWAL_PLAN') ?? ''),
            simulator: {
                mobile: env('NEXT_PUBLIC_DEFAULT_SIMULATOR_MOBILE_PLAN') ?? '',
                fai: env('NEXT_PUBLIC_DEFAULT_SIMULATOR_FAI_PLAN') ?? '',
            },
        },
        comparator: {
            url: env('NEXT_PUBLIC_COMPARATEUR') ?? '',
        },
        assets: {
            images: {
                url: env('NEXT_PUBLIC_ASSETS_IMAGES') ?? '',
            },
            cmsImages: {
                url: env('NEXT_PUBLIC_ASSETS_CMS_IMAGES') ?? '',
            },
            cms: {
                url: process.env.ASSETS_CMS ?? env('NEXT_PUBLIC_ASSETS_CMS') ?? '',
            },
            faq: {
                url: process.env.FAQ ?? process.env.NEXT_PUBLIC_FAQ ?? '',
            },
            sliders: {
                script: env('NEXT_PUBLIC_ASSETS_SLIDERS_SCRIPT') ?? '',
            },
            palpatine: {
                plans: process.env.PALPATINE_FORFAITS_API,
                banners: process.env.PALPATINE_BANNERS_API,
                sticker: process.env.PALPATINE_STICKER_API,
                product: process.env.PALPATINE_PRODUCT_API,
                fai: process.env.PALPATINE_FAI_API,
            },
            thumbor: {
                url: env('NEXT_PUBLIC_ASSETS_THUMBOR') ?? '',
            },
            trilogy: {
                script: env('NEXT_PUBLIC_ASSETS_TRILOGY_SCRIPT') ?? '',
            },
        },
        mdoMobileToggle: {
            active: env('NEXT_PUBLIC_MDO_MOBILE_TOGGLE_DISPLAYED') === 'true',
        },
        thumbor: {
            active: env('NEXT_PUBLIC_THUMBOR_ACTIVE') === 'true',
            url: env('NEXT_PUBLIC_ASSETS_THUMBOR') ?? '',
        },
        crossSell: {
            active: env('NEXT_PUBLIC_CROSS_SELL_ACTIVE') === 'true',
            accessoriesActive: env('NEXT_PUBLIC_CROSS_SELL_ACCESSORIES_ACTIVE') === 'true',
            maximumAccessories: parseInt(env('NEXT_PUBLIC_CROSS_SELL_MAX_ACCESSORIES') ?? ''),
        },
        tracking: {
            env: env('NEXT_PUBLIC_TRACKING_ENV') as 'PROD' | 'TEST',
            enabled: env('NEXT_PUBLIC_TRACKING_ENABLED') === 'true',
            host: env('NEXT_PUBLIC_TRACKING_HOST') ?? '',
            debug: env('NEXT_PUBLIC_TRACKING_DEBUG') === 'true',
        },
        logger: {
            http: {
                prisme: env('NEXT_PUBLIC_LOG_PRISME_ACTIVE') === 'true' || process.env.LOG_PRISME_ACTIVE === 'true',
                logCached: process.env.LOG_HTTP_CACHED === 'true',
            },
        },
        cache: {
            ttl: {
                products: env('NEXT_PUBLIC_CACHE_TTL_PRODUCTS') ?? '',
                productDetail: env('NEXT_PUBLIC_CACHE_TTL_PRODUCT_DETAIL') ?? '',
                productsStocks: env('NEXT_PUBLIC_CACHE_TTL_PRODUCTS_STOCKS') ?? '',
                numeriques: env('NEXT_PUBLIC_CACHE_TTL_NUMERIQUES') ?? '',
                cms: env('NEXT_PUBLIC_CACHE_TTL_CMS') ?? '',
            },
        },
        monitoring: {
            active: env('NEXT_PUBLIC_SENTRY_ACTIVE') === 'true' || process.env.SENTRY_ACTIVE === 'true',
            url: env('NEXT_PUBLIC_SENTRY_DSN'),
            bench: env('NEXT_PUBLIC_HELM_RELEASE_NAME'),
            debug: env('NEXT_PUBLIC_SENTRY_DEBUG') === 'true',
        },
        widget: {
            roc: {
                url: env('NEXT_PUBLIC_ROC_URL') ?? '',
            },
            weto: {
                url: env('NEXT_PUBLIC_WETO_URL') ?? '',
            },
        },
        yamaReferrers: env('NEXT_PUBLIC_YAMA_REFERRERS')?.split(',') ?? [],
    };
}

export const isServer = () => {
    return typeof window === 'undefined';
};
