import { getAppConfig } from '@services/config';
import { QUOTE_TYPES } from '@app-types/cart';
import { VirtualCartQuote } from '@app-types/simulator/virtual-cart';
import { API_X_VERSION } from '@constants/api';
export function getQuotePayload(clientBbox?: boolean, clientMobile?: boolean, phoneGencode?: string, apiVersion: API_X_VERSION = API_X_VERSION.V5, isConnected?: boolean): VirtualCartQuote[] {
  if (isConnected) {
    const quote: VirtualCartQuote = {
      isCurrent: true,
      products: [] as {
        gencode: string;
      }[],
      type: QUOTE_TYPES.ACQUISITION
    };
    if (phoneGencode) {
      quote?.products.push({
        gencode: phoneGencode
      });
    }
    return [quote];
  }
  const appConfig = getAppConfig();
  const mobileQuote: VirtualCartQuote = {
    isCurrent: true,
    type: QUOTE_TYPES.ACQUISITION,
    products: []
  };
  const faiQuote: VirtualCartQuote = {
    isCurrent: false,
    type: apiVersion === API_X_VERSION.V5 ? QUOTE_TYPES.ACQUISITION_FIXE : QUOTE_TYPES.ACQUISITION_FIXE_V4,
    products: [{
      gencode: `${appConfig.defaultPlans.simulator.fai}`
    }]
  };
  const quotePayload: VirtualCartQuote[] = [mobileQuote];
  if (clientBbox) {
    quotePayload.unshift(faiQuote);
  }
  if (clientMobile) {
    const acquisitionJourney = quotePayload.find(quote => quote.type === QUOTE_TYPES.ACQUISITION);
    if (acquisitionJourney) {
      acquisitionJourney.isCurrent = false;
      acquisitionJourney.products.push({
        gencode: `${appConfig.defaultPlans.simulator.mobile}`
      });

      // on passe un 2eme parcours mobile vide pour bénéficier du 2eme palier de remise
      quotePayload.push({
        isCurrent: true,
        type: QUOTE_TYPES.ACQUISITION,
        products: []
      });
    }
  }
  if (phoneGencode) {
    quotePayload.find(quote => quote.type === QUOTE_TYPES.ACQUISITION && quote.products.length === 0)?.products.push({
      gencode: phoneGencode
    });
  }
  return quotePayload;
}