import { Session } from 'next-auth';

import { ActiveFiltersType } from '@bytel/product-wall';

import { getAppConfig } from '@services/config';
import { apiHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { getIdPuFromContract } from '@actions/get-person';

import {
    SapicCategoryId,
    SapicContextualisedProductV4Payload,
    SapicContextualisedProductV5Payload,
    SapicCustomerCategory,
    SapicFundingMode,
    SapicPriceForFilter,
    SapicPriceForSort,
    SapicProductPayloadV4,
    SapicProductPayloadV5,
    SapicResponseTypeV4,
    SapicResponseTypeV5,
} from '@app-types/api/sapic';
import { QUOTE_TYPES } from '@app-types/cart';
import { FaiType, PlanType } from '@app-types/plan';
import { Product } from '@app-types/product';
import { SimulatorCart } from '@app-types/simulator/virtual-cart';
import { ForbiddenUserType, UserType, UserTypeEnum } from '@app-types/user';

import { SapicMapper } from '@helpers/mappers/sapic-mapper';

import { API_X_VERSION } from '@constants/api';
import { CATEGORY_TYPE, PLAY } from '@constants/provider';

// import { getPagination } from '@product-wall/helpers' //doesn't work 🤷‍♂️;
import { getPagination } from 'src/app/(product-wall)/_helpers/pagination';
import { z } from 'zod';

export type GetProductOptionType = {
    category?: SapicCategoryId;
    categories?: SapicCategoryId[];
    sort?: string;
    pageNumber?: number;
    limit?: number;
    clientCategory?: SapicCustomerCategory;
    fundingMode?: SapicFundingMode;
    priceFilter?: SapicPriceForFilter;
    priceSort?: SapicPriceForSort;
    filters?: ActiveFiltersType;
    plan?: string;
    autoComplete?: string;
    renewal?: boolean;
    contract?: string;
    stickers?: string[];
    gencodes?: string[];
    withDetails?: boolean;
    filterByTypes?: SapicCategoryId[];
    excludeCategoryType?: CATEGORY_TYPE;
    play?: PLAY;
};

const getProductPayloadApiSchema = z.object({
    panier: z.object({
        parcours: z.array(
            z.object({
                type: z.enum([
                    QUOTE_TYPES.ACQUISITION,
                    QUOTE_TYPES.ACQUISITION_FIXE,
                    QUOTE_TYPES.ACQUISITION_FIXE_V4,
                    QUOTE_TYPES.RENEWAL,
                ]),
                estCourant: z.boolean(),
                produits: z.array(z.object({ gencode: z.string() })),
                idContrat: z.string().optional(),
            }),
        ),
    }),
});

const getProductV5PayloadApiSchema = z.object({
    referenceExterneParcoursCible: z.string(),
    panier: z.object({
        catalogue: z.literal('WEB'),
        acheteur: z
            .object({
                type: z.enum(['PERSONNE', 'NON_CONNU_GP', 'NON_CONNU_PRO']),
                noPersonne: z.string().optional(),
            })
            .optional(),
        parcours: z.array(
            z.object({
                referenceExterne: z.string(),
                typeParcours: z.union([z.literal(QUOTE_TYPES.ACQUISITION), z.literal(QUOTE_TYPES.ACQUISITION_FIXE)]),
                statut: z.union([z.literal('EN_COURS'), z.literal('TERMINE')]),
                elements: z.array(
                    z.object({
                        referenceExterne: z.string(),
                        composantCommercial: z.object({ identifiant: z.string(), typeCatalogue: z.literal('BYTEL') }),
                    }),
                ),
            }),
        ),
    }),
});

class SapicRepository {
    private readonly STORE = 'web';
    private readonly url: string;

    private defaultGetProductsOptions: GetProductOptionType = {
        sort: 'nouveautes',
        pageNumber: 0,
        limit: 12,
        clientCategory: SapicCustomerCategory.GP,
        fundingMode: SapicFundingMode.AUTO,
        priceFilter: SapicPriceForFilter.INITIAL,
        priceSort: SapicPriceForSort.INITIAL,
        renewal: false,
        withDetails: false,
    };

    public constructor(url: string) {
        this.url = url;
    }

    public async getFaiPlans(
        virtualCart: SimulatorCart,
        options: GetProductOptionType,
        session: Session | null = null,
    ): Promise<{ products: FaiType[]; count: number }> {
        return this.contextualisedProducts<SapicResponseTypeV5>(virtualCart, options, session, API_X_VERSION.V5).then(
            (response) => ({
                products: response.produits.map((product) => SapicMapper.mapToFai(product)),
                count: response.nombreTotalProduits,
            }),
        );
    }

    public async getPlans(
        virtualCart: SimulatorCart,
        options: GetProductOptionType,
        session: Session | null = null,
        apiVersion: API_X_VERSION = API_X_VERSION.V5,
    ): Promise<{ products: PlanType[]; count: number }> {
        return this.contextualisedProducts<SapicResponseTypeV4 | SapicResponseTypeV5>(
            virtualCart,
            options,
            session,
            apiVersion,
        )
            .then((response) => ({
                products: response.produits.map((product) => SapicMapper.mapToPlan(product)),
                count: response.nombreTotalProduits,
            }))
            .catch(() => {
                return {
                    products: [],
                    count: 0,
                };
            });
    }

    public async getProducts(
        virtualCart: SimulatorCart,
        options: GetProductOptionType,
        session: Session | null = null,
        xVersion: API_X_VERSION = API_X_VERSION.V4,
    ): Promise<{ products: Product[]; count: number }> {
        // @todo add custom cache headers 'x-cache': (parseInt(appConfig.cache.ttl.products) * 60).toString(),
        return this.contextualisedProducts<SapicResponseTypeV4>(virtualCart, options, session, xVersion).then(
            (response) => ({
                products: response.produits.map((product) => SapicMapper.mapToProduct(product, options.renewal)),
                count: response.nombreTotalProduits,
            }),
        );
    }

    private async contextualisedProducts<T>(
        virtualCart: SimulatorCart,
        options: GetProductOptionType,
        session: Session | null = null,
        xVersion: API_X_VERSION = API_X_VERSION.V4,
    ): Promise<T> {
        const headers: Record<string, string> = {
            'x-version': `${xVersion}`,
            'x-process': 'ConsulterProduitsContextualises',
        };

        let idPu = undefined;
        if (session?.user?.user_type) {
            if (!ForbiddenUserType.includes(session?.user?.user_type as UserTypeEnum)) {
                idPu = session?.user?.sub;
            }

            if (
                ForbiddenUserType.includes(session?.user?.user_type as UserTypeEnum) &&
                (options.contract || virtualCart?.cart?.quotes[0]?.contractId)
            ) {
                idPu = await getIdPuFromContract(options.contract || virtualCart?.cart?.quotes[0]?.contractId);
            }
        }

        try {
            let body;
            let params;
            if (xVersion === API_X_VERSION.V5) {
                body = getProductV5PayloadApiSchema.parse(this.createSapicV5Payload(virtualCart, idPu));
                params = this.generateSearchParamV5(options);
            } else {
                body = getProductPayloadApiSchema.parse(this.createSapicPayload(virtualCart, idPu));
                params = this.generateSearchParamV4(options);
            }

            return await apiHttpService.post<T>(
                `${this.url}/catalogue/produits-contextualises?${new URLSearchParams(params)}`,
                body,
                {
                    headers,
                    user: idPu ? (session?.user as UserType) : undefined,
                    ttl: parseInt(getAppConfig().cache.ttl.products),
                    tags: [CACHE_TAGS.PRODUCTS],
                },
            );
        } catch (error) {
            console.log('Could not fetch sapic products', error);
            throw new Error('Could not fetch sapic products');
        }
    }

    private createSapicPayload(virtualCart: SimulatorCart, idPu?: string): SapicContextualisedProductV4Payload {
        const payload: SapicContextualisedProductV4Payload = {
            panier: {
                parcours:
                    virtualCart.cart.quotes?.map((quote) => {
                        const quotePayload: SapicContextualisedProductV4Payload['panier']['parcours'][0] = {
                            estCourant: quote.isCurrent,
                            type: quote.type,
                            produits:
                                quote.products?.map((product) => ({
                                    gencode: product.gencode,
                                })) || [],
                        };

                        if (quote.contractId) {
                            quotePayload.idContrat = quote.contractId;
                        }

                        return quotePayload;
                    }) || [],
            },
        };

        if (idPu) {
            payload.panier.acheteur = {
                type: 'GP',
                noPersonne: idPu,
            };
        }

        return payload;
    }

    private createSapicV5Payload(virtualCart: SimulatorCart, idPu?: string): SapicContextualisedProductV5Payload {
        const payload: SapicContextualisedProductV5Payload = {
            referenceExterneParcoursCible: '',
            panier: {
                catalogue: 'WEB',
                parcours: virtualCart.cart.quotes?.map((quote, quoteIndex) => ({
                    referenceExterne: `idParcours-${quoteIndex}`,
                    typeParcours: quote.type,
                    statut: quote.isCurrent ? 'EN_COURS' : 'TERMINE',
                    elements: quote.products?.map((product, index) => ({
                        referenceExterne: `idElement-${quoteIndex}-${index}`,
                        composantCommercial: {
                            identifiant: product.gencode,
                            typeCatalogue: 'BYTEL',
                        },
                    })),
                })),
            },
        };

        if (idPu) {
            payload.panier.acheteur = {
                type: 'PERSONNE',
                noPersonne: idPu,
            };
        } else {
            payload.panier.acheteur = {
                type: 'NON_CONNU_GP',
            };
        }

        payload.referenceExterneParcoursCible =
            payload.panier.parcours.find((p) => p.statut === 'EN_COURS')?.referenceExterne || '';
        return payload;
    }

    private generateSearchParamV5(options: GetProductOptionType) {
        const paramsV4 = this.generateSearchParamV4(options);

        let filter = '';

        if (options.filterByTypes) {
            filter = this.createFilter(options.filterByTypes);
        }

        if (options.play) {
            filter = filter ? `${filter} and play = "${options.play}"` : `play = "${options.play}"`;
        }

        if (options.excludeCategoryType) {
            filter = filter
                ? `${filter} and typeCategorieFai != "${options.excludeCategoryType}"`
                : `typeCategorieFai != "${options.excludeCategoryType}"`;
        }

        const params: SapicProductPayloadV5 = {
            ...paramsV4,
            ...(options.gencodes && { gencodes: options.gencodes.join(',') }),
        };

        if (filter) {
            params.filtre = filter;
        }

        return params;
    }

    private createFilter(categories: SapicCategoryId[] = []): string {
        let typeFilter = `type = "${categories[0]}"`;

        if (categories.length > 0) {
            const to = categories.map((category) => `"${category}"`).join(',');
            typeFilter = `type in (${to})`;
        }

        return typeFilter;
    }

    private generateSearchParamV4(options: GetProductOptionType) {
        const callbackOptions: GetProductOptionType = { ...this.defaultGetProductsOptions, ...options };

        let paginationData;

        if (callbackOptions.pageNumber && callbackOptions.pageNumber > 0 && callbackOptions.limit) {
            const stickersPosition =
                callbackOptions.stickers?.map((sticker) => {
                    return parseInt(sticker);
                }) ?? [];

            paginationData = getPagination(callbackOptions.pageNumber, callbackOptions.limit, stickersPosition);
        }

        const limit = paginationData?.limit ?? callbackOptions.limit;
        const offset = paginationData?.offset ?? 0;

        const searchParams = {
            boutique: this.STORE,
            clientCategorie: SapicCustomerCategory.GP,
            modePourFinancement: callbackOptions.fundingMode,
            prixReferencePourFiltre: callbackOptions.priceFilter,
            tri: callbackOptions.sort,
            detail: callbackOptions?.withDetails ? '1' : '0',
            limite: limit?.toString(),
            decalage: offset.toString(),
            prixReferencePourTri: callbackOptions.priceSort,
            ...(callbackOptions.category && { categorie: callbackOptions.category }),
            ...(callbackOptions.categories && { categorie: callbackOptions.categories.join(',') }),
            ...(callbackOptions.gencodes && { gencodes: callbackOptions.gencodes }),
        } as SapicProductPayloadV4;

        if (callbackOptions.autoComplete) {
            searchParams.nom = callbackOptions.autoComplete;
        }

        if (callbackOptions.filters) {
            Object.entries(callbackOptions.filters).forEach((filtersData) => {
                const key = filtersData[0] as keyof SapicProductPayloadV4;
                if (key === 'prix') {
                    searchParams[key] = this.getPriceRange(filtersData[1]).join('-');
                    return;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                searchParams[key] = filtersData[1].join(',');
            });
        }

        return searchParams;
    }

    private getPriceRange(priceRangeList: string[]): [number, number | undefined] {
        const defaultMin = Infinity;
        const defaultMax = -Infinity;

        const minMax = priceRangeList.reduce(
            ([min, max]: [number, number], currentValue): [number, number] => {
                let [currentMin, currentMax] = currentValue.split('-').map((value) => parseInt(value)) as [
                    number,
                    number,
                ];

                currentMin = isNaN(currentMin) ? -Infinity : currentMin;
                currentMax = isNaN(currentMax) ? Infinity : currentMax;

                min = min < currentMin ? min : currentMin;
                max = max > currentMax ? max : currentMax || Infinity;

                return [min, max];
            },
            [defaultMin, defaultMax],
        );

        const min = minMax[0] === -Infinity ? 0 : minMax[0];
        const max = minMax[1] === Infinity ? undefined : minMax[1];

        return [min, max];
    }
}

export const sapicRepository = new SapicRepository(getAppConfig().sapic.url);
