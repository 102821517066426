'use client';

import { useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { getServerSession, serverSignIn } from '@actions/get-session';
import { signIn } from '@helpers/authentication';
import { getClientCookies } from '@helpers/cookies-client';
import { isSessionExpired } from '@helpers/session';
export function TriggerLogin() {
  const queryParams = useSearchParams();
  useEffect(() => {
    getServerSession().then(session => {
      const shouldTriggerLogin = getClientCookies('SSO_ACTIVE') !== '' || queryParams.get('appli') === 'true';
      if (!session && shouldTriggerLogin || isSessionExpired(session)) {
        if (queryParams.has('AUTHORIZE_ID')) {
          document.cookie = `AUTHORIZE_ID=${queryParams.get('AUTHORIZE_ID')};path=/;domain=.bouyguestelecom.fr`;
        }
        void serverSignIn();
      } else {
        if (getClientCookies('AUTHORIZE_ID') !== '') {
          document.cookie = `AUTHORIZE_ID=;path=/;domain=.bouyguestelecom.fr;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        }
      }
    });
  }, []);
  return null;
}
export function ForceLogin() {
  const {
    status,
    data: session
  } = useSession();
  useEffect(() => {
    if (status === 'unauthenticated' || isSessionExpired(session)) {
      void signIn();
    }
  }, []);
  return null;
}