import Link from 'next/link';
import { ReactNode } from 'react';
type LinkCustomProps = {
  href: string;
  children: ReactNode;
  replace: boolean;
  scroll: boolean;
  prefetch: boolean;
};
export function LinkCustom({
  href,
  prefetch = false,
  children,
  ...props
}: LinkCustomProps) {
  return <Link href={href} prefetch={prefetch} {...props} data-sentry-element="Link" data-sentry-component="LinkCustom" data-sentry-source-file="link-custom.tsx">
            {children}
        </Link>;
}