import { Session } from 'next-auth';
import { getAccordionSeo } from '@services/cms/accordion-seo';
import { getSlider } from '@services/cms/sliders';
import { getWallConfig } from '@services/config';
import { getCurrentPlan, getWallProducts } from '@services/products';
import { getStickers } from '@actions/sticker';
import { WallType } from '@app-types/config';
import { ContractType } from '@app-types/contract';
import { SearchParamsType } from '@app-types/page';
import { PlanProvenanceType } from '@app-types/plan';
import { UserType } from '@app-types/user';
import { ImageThumbor } from '@components/image-thumbor';
import { generateSubtitle, getActiveFilters } from '@helpers/seo';
import { getSearchList } from '@product-wall/actions';
import { Wall } from './wall';
const wallConfig = getWallConfig(WallType.PHONE);
export async function PhoneWall({
  isRenewal = false,
  contract,
  contractId,
  searchParams,
  params,
  session,
  handleLogin = true
}: Readonly<{
  isRenewal?: boolean;
  contract?: ContractType;
  contractId?: string;
  searchParams?: SearchParamsType;
  params?: {
    filter: string;
  };
  session?: Session | null;
  handleLogin?: boolean;
}>) {
  const page = typeof searchParams?.page === 'string' ? Number(searchParams.page) : 1;
  const sort = typeof searchParams?.sort === 'string' ? searchParams.sort : 'meilleures-ventes';
  const activeFilters = getActiveFilters(wallConfig, params?.filter ?? '', new URLSearchParams(searchParams));
  const autoComplete = searchParams?.nom;
  const planPromise = getCurrentPlan(new URLSearchParams(searchParams), undefined, isRenewal, contract);
  const stickersPromise = getStickers(wallConfig, activeFilters, isRenewal);
  const [plan, stickers] = await Promise.all([planPromise, stickersPromise]);
  const productsPromise = contractId || !isRenewal ? getWallProducts(WallType.PHONE, {
    sort,
    pageNumber: page,
    limit: wallConfig.productByPage,
    filters: activeFilters,
    plan: plan.gencode,
    autoComplete,
    renewal: isRenewal,
    contract: contractId,
    stickers: Object.keys(stickers)
  }, session) : Promise.resolve({
    products: [],
    count: 0
  });
  const sliderPromise = getSlider(wallConfig, activeFilters, isRenewal);
  const accordionSeoPromise = getAccordionSeo(wallConfig, activeFilters, isRenewal);
  const searchListPromise = getSearchList(WallType.PHONE);
  const [{
    products,
    count
  }, slider, accordionSeo, searchList] = await Promise.all([productsPromise, sliderPromise, accordionSeoPromise, searchListPromise]);
  const subtitle = plan.origin !== PlanProvenanceType.DEFAULT ? generateSubtitle(plan) : undefined;
  return <Wall title={wallConfig.baseTitle} type={WallType.PHONE} products={products} pageSize={wallConfig.productByPage} count={count} wallConfig={wallConfig} startPage={page} activeFilters={activeFilters} activeSort={sort} searchParams={searchParams} slider={slider} accordionSeo={accordionSeo} stickers={stickers} plan={plan} subtitle={subtitle} enableKonami enableHandsFree enableCompare searchList={searchList} autoComplete={autoComplete} isRenewal={isRenewal} user={session?.user as UserType | undefined} contract={contract} isLoading={isRenewal && !contractId} handleLogin={handleLogin} imageAs={ImageThumbor} trackingData={{
    page: 'Mur_de_telephone',
    pageCategory: 'Liste_produits_telephone',
    marketLine: 'Mobile',
    isB2B: false,
    eCommerceStep: 'Liste_produits',
    variant: {
      Recommandation: products.some(product => product.recommended)
    }
  }} data-sentry-element="Wall" data-sentry-component="PhoneWall" data-sentry-source-file="wall-phone.tsx" />;
}