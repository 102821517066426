import { getAppConfig } from '@services/config';

import { TagCommanderType, TrackingType } from '@app-types/tracking';
import { UserType } from '@app-types/user';

import { isServer } from '@helpers/config';
import { getServerSession } from '@helpers/session';

class TrackingManager {
    private appConfig = getAppConfig();
    private readonly tag: Promise<TagCommanderType> | undefined;
    private isReady = false;
    private queue: { isRenewal: boolean; data: TrackingType }[] = [];

    constructor() {
        if (typeof window !== 'undefined') {
            this.tag = import('@bytel/bytel-tag-commander').then((module) => module.default);
        }
    }

    public setup(): TrackingManager {
        this.tag?.then(async (tag) => {
            if (!tag) {
                throw new Error('tracking must be setup from the browser');
            }

            setTimeout(async () => {
                let user: UserType | undefined;
                try {
                    const session = await getServerSession();
                    user = session?.user as UserType | undefined;
                } catch (e) {}

                await tag.setup({
                    host: this.appConfig.tracking.host,
                    debug: this.appConfig.tracking.debug,
                    env: this.appConfig.tracking.env,
                    site: this.getSite(),
                    oauth: user
                        ? {
                              id_personne: user.sub,
                              user_type: user.user_type,
                              accessToken: user.accessToken,
                          }
                        : {
                              callback: `${window.location.origin}/connexion/callback2.html`,
                              clientId: this.appConfig.oauth2.clientId,
                              url: this.appConfig.oauth2.url,
                          },
                    shouldEmitEvents: true,
                    hasHistory: true,
                });

                this.isReady = true;

                this.queue.forEach(({ isRenewal, data }) => this.sendTracking(isRenewal, data));

                this.queue = [];
            }, 0);
        });

        return this;
    }

    public async sendTracking(isRenewal: boolean, data: TrackingType) {
        const tag = await this.tag;

        if (!tag) {
            throw new Error('tracking must be sent from the browser');
        }

        if (!this.isReady) {
            this.queue.push({ isRenewal, data });
            return;
        }

        try {
            await tag.send(
                JSON.parse(
                    JSON.stringify({
                        site: this.getSite(),
                        univers: data.universe ?? 'Boutique_mobile',
                        parcours: data.journey ?? (isRenewal ? 'Renouvellement' : 'Acquisition'),
                        url: data.url ?? window.location.href,
                        page: data.page,
                        pageCategorie: data.pageCategory,
                        actionClic: data.action,
                        categorieClic: data.category,
                        nomClic: data.name,
                        ligneMarche: data.marketLine,
                        activiteB2BouB2C: data.isB2B ? 'B2B' : 'B2C',
                        etapeEcommerce: data.eCommerceStep,
                        pageVariante: data.variant,
                        variablesSpecifiques: data.variables,
                        produits: data.products,
                    }),
                ),
            );
        } catch {}
    }

    private getSite() {
        if (isServer()) {
            throw new Error('Could not get tracking site from server side');
        }

        const bankCartData = localStorage.getItem('yama_cart');
        const isActiveBankCart =
            bankCartData !== null && Number(JSON.parse(bankCartData)?.expiresTimestamp) > new Date().getTime();
        return isActiveBankCart ? 'Partenaire_cfcm_boutique' : 'Boutique';
    }
}

export const trackingManager = new TrackingManager().setup();
