import NextAuth from 'next-auth';

import { getAppConfig } from '@services/config';
import { oauthLoggerService } from '@services/loggers/oauth-logger.service';

import { Oauth2UserType } from '@app-types/oauth2';

import { JWT } from '@auth/core/jwt';

const appConfig = getAppConfig();

export const {
    handlers: { GET, POST },
    auth,
    signIn,
} = NextAuth({
    secret: process.env.NEXTAUTH_SECRET,
    trustHost: true,
    cookies: {
        sessionToken: {
            name: `next-session-token`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
        callbackUrl: {
            name: `next-callback-url`,
            options: {
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
        csrfToken: {
            name: `next-csrf-token`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
        pkceCodeVerifier: {
            name: `next-pkce-code-verifier`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
                maxAge: 900,
            },
        },
        state: {
            name: `next-auth-state`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
                maxAge: 900,
            },
        },
        nonce: {
            name: `next-auth-nonce`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
    },
    providers: [
        {
            id: 'oauth2',
            name: 'Oauth2',
            issuer: appConfig.oauth2.url,
            type: 'oidc',
            checks: ['pkce', 'state'],
            clientId: appConfig.oauth2.clientId,
            authorization: {
                url: appConfig.oauth2.authorizationUrl,
                params: { scope: appConfig.oauth2.scope },
            },
            token: {
                url: appConfig.oauth2.tokenUrl,
            },
            userinfo: {
                url: appConfig.oauth2.userInfoUrl,
            },
            client: {
                token_endpoint_auth_method: 'none',
            },
            profile(profile: Oauth2UserType) {
                return { id: profile.sub, ...profile };
            },
        },
    ],
    logger: {
        debug(code, metadata) {
            oauthLoggerService.log(code, metadata);
        },
    },
    session: {
        maxAge: 60 * 60,
    },
    callbacks: {
        async session({ session: { expires }, token }) {
            return {
                user: token,
                expires,
            };
        },
        async jwt({ token, profile, account }) {
            let newToken: JWT = token;

            if (profile) {
                newToken = profile as JWT;
            }

            if (account) {
                newToken = { ...newToken, accessToken: account?.access_token };
            }

            return newToken;
        },
    },
});
