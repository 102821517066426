import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Container, Text, TextLevels, TrilogyColor, TypographyBold } from '@bytel/trilogy-react-ts';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { Price } from '@components/price';
import { isCofidis, isYounited } from '@helpers/funding';
type FundingLegalsProps = {
  funding: FundingType;
  type?: never;
} | {
  type: FundingEnumType.YOUNITED | FundingEnumType.COFIDIS;
  funding?: never;
};
export function FundingLegals({
  type,
  funding
}: Readonly<FundingLegalsProps>) {
  if (funding) {
    type = isYounited(funding.type) ? FundingEnumType.YOUNITED : FundingEnumType.COFIDIS;
  }
  return <Container fluid background={TrilogyColor.WHITE} data-cy='funding-legals' data-sentry-element="Container" data-sentry-component="FundingLegals" data-sentry-source-file="funding-legals.tsx">
            <Accordion data-sentry-element="Accordion" data-sentry-source-file="funding-legals.tsx">
                <AccordionItem id='product-detail-funding-legals' active data-sentry-element="AccordionItem" data-sentry-source-file="funding-legals.tsx">
                    <AccordionHeader toggle data-sentry-element="AccordionHeader" data-sentry-source-file="funding-legals.tsx">Mentions légales du financement</AccordionHeader>
                    <AccordionBody data-sentry-element="AccordionBody" data-sentry-source-file="funding-legals.tsx">
                        <Text typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD} level={TextLevels.ONE} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                            Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant
                            de vous engager.
                        </Text>
                        {funding && <Text level={TextLevels.TWO}>
                                <b>
                                    {funding.installmentCount} mensualités de{' '}
                                    <Price className='is-6' amount={funding.monthlyAmount} inline period='mois' />
                                </b>
                                .{' '}
                                <b>
                                    Montant total dû{' '}
                                    <Price className='is-6' amount={funding.totalFinancingCost} inline />
                                </b>{' '}
                                (hors assurance facultative). <b>TAEG fixe&nbsp;: {funding.APR?.toLocaleString()}%</b>.
                                Taux débiteur fixe de {funding.fixedInterestRate?.toLocaleString()}%.
                            </Text>}
                        {isYounited(type!) && <YounitedLegals />}
                        {isCofidis(type!) && <CofidisLegals />}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </Container>;
}
function YounitedLegals() {
  return <>
            <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                Younited est agréé en tant qu&apos;établissement de crédit - Prestataire de Services
                d&apos;Investissement par l&apos;Autorité de Contrôle Prudentiel et de Résolution (ACPR, n°
                d&apos;agrément&nbsp;: 16488).
            </Text>
            <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                Le financement est accordé sous réserve d&apos;acceptation définitive après l&apos;étude de votre
                dossier. Vous disposez d&apos;un droit de rétractation de 14 jours calendaires révolus à compter de la
                signature de l&apos;offre de contrat de crédit.
            </Text>
            <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                Younited&nbsp;: SA à Directoire et Conseil de Surveillance au capital de 1&nbsp;934&nbsp;272&nbsp;€ -
                Siège social&nbsp;: 21 rue de Châteaudun - 75009 PARIS - RCS de Paris 517&nbsp;586&nbsp;376 - Numéro
                ORIAS 11061269
            </Text>
        </>;
}
function CofidisLegals() {
  return <>
            <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                COFIDIS est agréé en tant qu&apos;établissement de crédit par l&apos;Autorité de Contrôle Prudentiel et
                de Résolution (ACPR, n° d&apos;agrément&nbsp;: 14940), 4 place de Budapest – CS 92459 – 75436 Paris
                CEDEX 09.
            </Text>
            <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                Le financement est accordé sous réserve d&apos;acceptation définitive après l&apos;étude de votre
                dossier. Vous disposez d&apos;un droit de rétractation de 14 jours calendaires révolus à compter de la
                signature de l&apos;offre de contrat de crédit.
            </Text>
            <Text level={TextLevels.TWO} data-sentry-element="Text" data-sentry-source-file="funding-legals.tsx">
                COFIDIS&nbsp;: Société Anonyme à Directoire et Conseil de surveillance au capital de 67 500 000 € –
                Siège social&nbsp;: Parc de la Haute Borne, 61 avenue Halley, 59866 Villeneuve d&apos;Ascq – RCS Lille
                Métropole 325307106
            </Text>
        </>;
}