import { getSessionVariable, removeSessionVariable, setSessionVariable } from '@services/storage';

import { FundingEnumType } from '@app-types/funding';

const SESSION_KEY = 'funding_key';

export const isYounited = (type: FundingEnumType): boolean => {
    return type.toUpperCase().includes(FundingEnumType.YOUNITED);
};

export const isCofidis = (type: FundingEnumType): boolean => {
    return type.toUpperCase().includes(FundingEnumType.COFIDIS);
};

export const isEDP = (type: FundingEnumType): boolean => {
    return type.toUpperCase() === FundingEnumType.EDP;
};

export const isComptant = (type: FundingEnumType): boolean => {
    return type.toUpperCase() === FundingEnumType.COMPTANT;
};

export const setSessionFunding = (type: FundingEnumType): void => {
    setSessionVariable(SESSION_KEY, type);
};

export const getSessionFunding = (): FundingEnumType | null => {
    return getSessionVariable(SESSION_KEY) as FundingEnumType | null;
};

export const removeSessionFunding = (): void => {
    removeSessionVariable(SESSION_KEY);
};
