import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { PlanType } from '@app-types/plan';

export function getFundingPhone(product: PlanType, phoneGencode: string): FundingType[] {
    if (!product?.simulatedCart?.quotes[0]?.products) return [];

    const phone = product.simulatedCart.quotes[0]?.products.find((el) => el.gencode === phoneGencode);
    const fundings = phone?.details.fundings ?? [];
    const promotionODR = phone?.details.discounts.filter((promo) => promo.type === DiscountEnumType.ODR)[0]?.value ?? 0;

    if (fundings.length > 0) {
        return fundings.map((funding) => {
            funding.initialContributionWithOdr =
                promotionODR > 0 ? Math.max(funding.initialDeposit - promotionODR, 1) : funding.initialDeposit;
            return funding;
        });
    } else {
        return [];
    }
}

export function getFundingOrder(type: FundingEnumType): number {
    switch (type) {
        case FundingEnumType.EDP:
            return 1;
        case FundingEnumType.COMPTANT:
            return 2;
        default:
            return 0;
    }
}

export function getDefaultFunding(fundings: FundingType[], presetValue?: string | null): FundingType | undefined {
    return fundings?.find((funding) => (presetValue ? funding.type === presetValue : funding.favorite)) || fundings[0];
}
