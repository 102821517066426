'use client';

import type { BreadcrumbList, Product } from 'schema-dts';
type StructuredDataProps = {
  data: Product | BreadcrumbList;
};
export function StructuredData({
  data,
  ...other
}: StructuredDataProps) {
  return <script type='application/ld+json' dangerouslySetInnerHTML={{
    __html: JSON.stringify({
      '@context': 'http://schema.org/',
      ...data
    })
  }} {...other} data-sentry-component="StructuredData" data-sentry-source-file="structured-data.tsx" />;
}