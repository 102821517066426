import { ProductType } from '@app-types/product';

export const paths = {
    index: '/',
    phoneWall: '/telephones-mobiles',
    renewalPhoneWall: `/telephones-mobiles/renouvellement`,
    phoneDetail: (slug: string, isRenewal: boolean = false) =>
        `${paths.phoneWall}/${isRenewal ? 'renouvellement/' : ''}${slug}`,
    phoneFilter: (isRenewal: boolean, filter: string) =>
        `${isRenewal ? paths.renewalPhoneWall : paths.phoneWall}/filtre/${filter}`,
    accessoryWall: '/accessoires',
    accessoryDetail: (slug: string) => `${paths.accessoryWall}/${slug}`,
    productDetail: (type: string, slug: string, isRenewal: boolean = false) => {
        switch (type) {
            case ProductType.ACCESSORY:
            case ProductType.ACCESSORY_CONFIGURABLE:
                return paths.accessoryDetail(slug);
            case ProductType.PHONE:
            case ProductType.PHONE_SIMPLE:
                return paths.phoneDetail(slug, isRenewal);
            default:
                return paths.index;
        }
    },
    productWall: (type: ProductType, isRenewal: boolean = false) => {
        switch (type) {
            case ProductType.ACCESSORY:
            case ProductType.ACCESSORY_CONFIGURABLE:
                return paths.accessoryWall;
            case ProductType.PHONE:
            case ProductType.PHONE_SIMPLE:
                return isRenewal ? paths.renewalPhoneWall : paths.phoneWall;
            default:
                return paths.index;
        }
    },
    directCart: (planId: string, faiRange: string, from?: string) => {
        const hasFrom = from ? `&from=${from}` : '';
        return `${paths.mixedCartEligibility}?plan_id=${planId}&gamme=${faiRange}${hasFrom}`;
    },
    homeFai: '/offres-internet',
    homePack: '/offres-internet/combo-box',
    homePackSmartTv: '/offres-internet/combo-box/smart-tv',
    promos: '/offres-box-forfait',
    crossSell: (origin?: string) => {
        const originParam = origin ? `?origin=${origin}` : '';
        return `/personnaliser-mon-offre${originParam}`;
    },
    plans: '/forfaits-mobiles/avec-engagement',
    renewalPlans: '/forfaits-mobiles/renouvellement',
    cart: '/panier',
    recall: '/rappelez-moi',
    mixedCartEligibility: '/offres-internet/test-eligibilite-panier-mixte',
    gamingEligibility: '/offres-internet/test-eligibilite-gaming',
    tabletEligibility: '/offres-internet/test-eligibilite-tablette',
    smartTvEligibility: '/offres-internet/test-eligibilite-smart-tv',
    youngEligibility: '/offres-internet/test-eligibilite-moins-26-ans',
    bankEligibility: '/offres-internet/test-eligibilite-bboxbanque',
    convergenceEligibility: '/offres-internet/test-eligibilite-ssunique',
    promoBboxEligibility: '/offres-internet/promo-bbox/test-eligibilite',
    eligibilityTest: '/offres-internet/test-eligibilite',
    offersComparator: '/offres-internet/comparateur',
    clientSpace: '/mon-compte',
};
