// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"881c58f17889c71fcad4cfbf8d857592b27bce4b"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/statics/ecommerce";import * as Sentry from '@sentry/nextjs';
import { getAppConfig } from '@services/config';

setTimeout(() => {

    const appConfig = getAppConfig();

    Sentry.init({
        dsn: appConfig.monitoring.url,
        enabled: appConfig.monitoring.active,
        environment: appConfig.monitoring.bench,

        // Add optional integrations for additional features
        integrations: [
            Sentry.replayIntegration(),
            Sentry.browserProfilingIntegration(),
        ],

        // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
        tracesSampleRate: 1.0,

        // This function will be called for every sampled span
        // to determine if it should be profiled
        profilesSampler: (samplingContext) => {
            return 1.0;
        },

        // Define how likely Replay events are sampled.
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // Define how likely Replay events are sampled when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // debug: process.env.NODE_ENV !== 'production',
        debug: appConfig.monitoring.debug
    });

    console.log('Monitoring config :::', appConfig.monitoring);
}, 200);

