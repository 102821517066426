'use client';

import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Cms } from '@bytel/product-wall';
import { calculatePrice } from '@bytel/product-wall/helpers';
import { Alignable, Box, Checkbox, Columns, ColumnsItem, Icon, IconName, IconSize, Justifiable, Link, Modal, RowItem, Rows, Text, TextMarkup, Title, TitleLevels, TrilogyColor, View } from '@bytel/trilogy-react-ts';
import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';
import { PlanPrice } from '@services/products';
import { PlanType } from '@app-types/plan';
import { Price } from '@components/price';
import { SSIBlock } from '@components/ssi-block';
import { PlanRange } from '@constants/plan';
import { LockedPlanValue, getBlockedPlans, setPlanLock } from '@home-mobile/helpers';
type ModalPlanDetailsProps = {
  plan: PlanType;
  price: PlanPrice;
  isOpen: boolean;
  onClose: () => void;
  isRenewal?: boolean;
  isPro?: boolean;
  showBlockedCheckbox?: boolean;
  children?: ReactNode;
};
const appConfig = getAppConfig();
export function ModalPlanDetails({
  plan,
  isOpen,
  onClose,
  isRenewal = false,
  isPro = false,
  price,
  showBlockedCheckbox = false,
  children
}: ModalPlanDetailsProps) {
  const [hasInternationalCallOpened, setHasInternationalCallOpened] = useState(false);
  const [cmsContent, setCmsContent] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const planTitle = useMemo(() => {
    if (plan.isDarwin) {
      return 'Le forfait qui grandit avec votre ado...';
    } else if (plan.hasLowPrice) {
      return "L'essentiel pour bien communiquer...";
    } else {
      return 'Le plein de gigas, mais pas seulement...';
    }
  }, [plan.isDarwin, plan.hasLowPrice]);
  useEffect(() => {
    let type = 'simo';
    if (plan.commercialRange === PlanRange.B_YOU) {
      type = 'sowo';
    } else if (plan.commercialRange === PlanRange.WITH_MOBILE) {
      type = 'sas';
    }
    cmsHttpService.get<string>(`${appConfig.assets.cms.url}/mobiles/${isRenewal ? 'renouvellement/' : ''}${isPro ? 'pro/' : ''}${type}/popin-${plan.dataEnvelope?.replace(/ /g, '_')?.replace(/à/g, 'a')?.toLowerCase()}`, undefined, {
      ttl: parseInt(appConfig.cache.ttl.cms),
      tags: [CACHE_TAGS.CMS]
    }).then(response => {
      setCmsContent(response);
    }).catch(() => {
      setCmsContent('');
    });
  }, [plan.commercialRange, plan.dataEnvelope, isRenewal, isPro]);
  useEffect(() => {
    if (plan.entityId) {
      setIsLocked(getBlockedPlans()[plan.entityId] ?? false);
    }
  }, [plan.entityId]);
  return <Modal onClose={onClose} active={isOpen} closeIcon data-sentry-element="Modal" data-sentry-component="ModalPlanDetails" data-sentry-source-file="plan-details.tsx">
            <div className='modal-title has-text-left'>
                <View flexable justify={Justifiable.SPACE_BETWEEN} align={Alignable.ALIGNED_CENTER} data-sentry-element="View" data-sentry-source-file="plan-details.tsx">
                    <Text marginless data-sentry-element="Text" data-sentry-source-file="plan-details.tsx">
                        <Text markup={TextMarkup.SPAN} data-sentry-element="Text" data-sentry-source-file="plan-details.tsx">
                            {plan.name.includes('Forfait') ? plan.name : `Forfait ${plan.name}`}
                        </Text>
                        {showBlockedCheckbox && plan.locked === LockedPlanValue.TWO && <Text markup={TextMarkup.SPAN}> - Forfait bloqué</Text>}
                    </Text>
                    {showBlockedCheckbox && plan.locked === LockedPlanValue.ONE && <Checkbox className='mr-3' label='Forfait bloqué' value={plan.entityId} checked={isLocked} onChange={value => setPlanLock(value.checkboxValue, value.checkboxChecked, isRenewal)} />}
                </View>
            </div>
            <div className='has-text-left'>
                <Price amount={calculatePrice(price.final, isPro)} period='mois' className='font-inherit' mention={isPro ? 'HT' : ''} data-sentry-element="Price" data-sentry-source-file="plan-details.tsx" />
                {price.promoDuration > 0 && <>
                        <Text markup={TextMarkup.SPAN}>&nbsp;pendant {price.promoDuration} mois puis&nbsp;</Text>
                        <Price amount={calculatePrice(price.forever, isPro)} period='mois' className='font-inherit' mention={isPro ? 'HT' : ''} />
                    </>}
                {plan.obligation && <>
                        <Text markup={TextMarkup.SPAN}>&nbsp;-&nbsp;</Text>
                        <Text markup={TextMarkup.P} className='capitalize-first is-inline-block' marginless>
                            {plan.obligationLabel}
                        </Text>
                    </>}
                <Title level={TitleLevels.THREE} data-sentry-element="Title" data-sentry-source-file="plan-details.tsx">{planTitle}</Title>
                {plan.fromAbroad && <Columns mobile verticalCentered>
                        <ColumnsItem narrow>
                            <Icon name={IconName.AT_GLOBE} size={IconSize.MEDIUM} />
                        </ColumnsItem>
                        <ColumnsItem>
                            <Text marginless markup={TextMarkup.SPAN} className='is-force-overflow-wrap'>
                                {plan.fromAbroad}
                            </Text>
                        </ColumnsItem>
                    </Columns>}
                {plan.hasDoubleSim && <Columns mobile verticalCentered>
                        <ColumnsItem narrow>
                            <Icon name={IconName.SIM_CARDS} size={IconSize.MEDIUM} />
                        </ColumnsItem>
                        <ColumnsItem>
                            <Text marginless>
                                Une 2ème carte SIM Internet pour utiliser votre forfait sur un second appareil
                                (tablette, montre...)
                            </Text>
                        </ColumnsItem>
                    </Columns>}
                {plan.isDarwin && <Columns mobile verticalCentered>
                        <ColumnsItem narrow>
                            <Icon name={IconName.CAREER_COMMITTEES} size={IconSize.MEDIUM} />
                        </ColumnsItem>
                        <ColumnsItem>
                            <Text marginless>
                                Tous les 6 mois, un nouveau palier, avec appels illimités et plus de gigas (10Go puis
                                20Go) en France métropolitaine
                            </Text>
                        </ColumnsItem>
                    </Columns>}
                {plan.smsMms && <Columns mobile verticalCentered>
                        <ColumnsItem narrow>
                            <Icon name={IconName.PHONE_WAITING_CALL} size={IconSize.MEDIUM} />
                        </ColumnsItem>
                        <ColumnsItem>
                            <Text marginless>{plan.smsMms}</Text>
                        </ColumnsItem>
                    </Columns>}
                {plan.internationalCall && <>
                        <Columns mobile verticalCentered>
                            <ColumnsItem narrow>
                                <Icon name={IconName.PHONE_OUTGOING_CALL} size={IconSize.MEDIUM} />
                            </ColumnsItem>
                            <ColumnsItem>
                                <Text marginless>
                                    <Text markup={TextMarkup.SPAN}>{`${plan.internationalCall} `}</Text>

                                    <Link role='button' onClick={() => setHasInternationalCallOpened(true)}>
                                        Plus de détails
                                    </Link>
                                </Text>
                            </ColumnsItem>
                        </Columns>
                        {hasInternationalCallOpened && <Box className='has-scroll-overflow' background={TrilogyColor.GREY10}>
                                <Icon className='is-absolute is-right-0 cursor-pointer' name={IconName.TIMES} onClick={() => setHasInternationalCallOpened(false)} size={IconSize.SMALL} />
                                <Rows className='is-marginless'>
                                    <RowItem>
                                        <SSIBlock tagId='internationalCall' url={`/partials/mobiles/banniere_forfaits-mobiles_destinations${isPro ? '_pro' : ''}`} />
                                    </RowItem>
                                </Rows>
                            </Box>}
                    </>}
                <Cms id='cms-details' content={cmsContent} data-sentry-element="Cms" data-sentry-source-file="plan-details.tsx" />
                {children}
            </div>
        </Modal>;
}