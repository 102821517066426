import { getAppConfig } from '@services/config';
import { getDeliveryModes } from '@services/products';

import { SapiProductChildType } from '@app-types/api/sapi';
import { ProductChildFull, ProductStockDetails } from '@app-types/product';
import { ShippingType, StockType } from '@app-types/product-shipping';

import { SHIPPING_MODES, STOCK_STATUS, StockConfigType } from '@constants/stock';
import { MILLIS_SECONDS_IN_A_DAY } from '@constants/time';

const appConf = getAppConfig();

export function getStockStatus(
    isEresa: boolean,
    isPreorder: boolean,
    isInStock: boolean,
    isAvailableSoon?: boolean,
    isSoonExhausted?: boolean,
): STOCK_STATUS {
    switch (true) {
        // Pré-commande
        case isPreorder && isInStock:
            return STOCK_STATUS.IN_STOCK_FROM;
        // Pas de stock && e-resa => Uniquement boutique
        case !isInStock && isEresa:
            return STOCK_STATUS.ONLY_IN_SHOP;
        // Pas de e-resa + config BO bientot dispo ==> bientôt disponible
        case !isInStock && !isEresa && isAvailableSoon:
            return STOCK_STATUS.SOON_AVAILABLE;
        // Bientôt épuisé
        case isSoonExhausted:
            return STOCK_STATUS.SOON_OUT_OF_STOCK;
        // Pas de stock
        case !isInStock:
            return STOCK_STATUS.OUT_OF_STOCK;
        default:
            return STOCK_STATUS.IN_STOCK;
    }
}

function getClosestDeliveryDate(deliveryModes: ShippingType): string | undefined {
    const closestDeliveryDate = deliveryModes
        .filter((mode) => [SHIPPING_MODES.LA_POSTE, SHIPPING_MODES.CHRONOPOST, SHIPPING_MODES.DIRECT].includes(mode.id))
        .map((mode) => new Date(mode.estimatedDeliveryDate))
        .sort((a, b) => a.getTime() - b.getTime())[0];

    if (!closestDeliveryDate) {
        return undefined;
    }

    const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];

    const now = new Date();
    const time = closestDeliveryDate.getTime() - now.getTime();

    switch (true) {
        case time < MILLIS_SECONDS_IN_A_DAY:
            return 'dès demain';
        case time < MILLIS_SECONDS_IN_A_DAY * 6:
            return `à partir de ${days[closestDeliveryDate.getDay()]}`;
        default:
            const day = `0${closestDeliveryDate.getDate()}`.slice(-2);
            const month = `0${closestDeliveryDate.getMonth() + 1}`.slice(-2);
            return `à partir du ${day}/${month}`;
    }
}

export async function getStockOptions(
    stock: StockConfigType,
    child: SapiProductChildType | ProductChildFull,
    productStock: StockType,
    userZipCode?: string,
): Promise<Omit<ProductStockDetails, 'stock'>> {
    let deliveryModes: ShippingType = [];
    let closestShippingDate: string | undefined;
    let canFreeShipping = false;

    if (stock.hasShipping) {
        deliveryModes = await getDeliveryModes(child.gencode, userZipCode);
        if (deliveryModes.length > 0) {
            canFreeShipping = deliveryModes.some(
                (mode) => mode.id !== SHIPPING_MODES.CLICK_AND_COLLECT && mode.price === 0,
            );
            closestShippingDate = getClosestDeliveryDate(deliveryModes);
        }
    }

    if ('stockDetails' in child) {
        // ProductChildFull
        const hasClickAndCollect = child.stockDetails.hasClickAndCollect && !child.isOnlyWeb && stock.hasCnC;

        return {
            closestShippingDate,
            hasClickAndCollect,
            hasFreeShipping: !hasClickAndCollect && canFreeShipping && stock.hasStock,
            isAvailableInStore:
                !child.isOnlyWeb && (!child.stockDetails.isInStock || child.quantity === 0) && productStock.isEresa,
            hasStockInStore: child.quantity
                ? child.quantity > 0 && child.stockDetails.isInStock && !child.isOnlyWeb && !productStock.isPreresa
                : false,
            isPreresaInStore: productStock.isPreresa,
            isPreresa: productStock.isPreresa,
            isPreorder: child.stockDetails.isPreorder,
            isInStock: child.stockDetails.isInStock,
            isSoonExhausted: child.stockDetails.isSoonExhausted,
            isAvailableSoon: child.stockDetails.isAvailableSoon,
            campaignId: child.campaignId,
        };
    } else {
        //SapiProductChildType
        const hasClickAndCollect = !!child.available_click_and_collect && !child.exclu_web && stock.hasCnC;

        return {
            closestShippingDate,
            hasClickAndCollect,
            hasFreeShipping: !hasClickAndCollect && canFreeShipping && stock.hasStock,
            isAvailableInStore: !child.exclu_web && (!child.in_stock || child.qty === 0) && productStock.isEresa,
            hasStockInStore: child.qty > 0 && child.in_stock && !child.exclu_web && !productStock.isPreresa,
            isPreresaInStore: productStock.isPreresa,
            isPreresa: productStock.isPreresa,
            isPreorder: child.is_preorder,
            isInStock: child.in_stock,
            isSoonExhausted: child.soon_exhausted,
            isAvailableSoon: child.available_soon,
            campaignId: child.campaign_id,
        };
    }
}

export function isOutOfStock(status: STOCK_STATUS) {
    return [STOCK_STATUS.OUT_OF_STOCK, STOCK_STATUS.ONLY_IN_SHOP, STOCK_STATUS.SOON_AVAILABLE].includes(status);
}

export function getProductEresaUrl(currentProduct: ProductChildFull, campaignId: string): string {
    const {
        gencode,
        stockDetails: { isPreresa },
    } = currentProduct;
    if (isPreresa) {
        return `${appConf.eresa.preorder}/${campaignId}/step-store?productId=${gencode}&headerFooter=0&origin=web`;
    } else {
        return `${appConf.eresa.locator}?productId=${gencode}&isProductLocator=1&headerFooter=0&origin=web`;
    }
}
