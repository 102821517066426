'use client';

import Image from 'next/image';
import { calculatePrice } from '@bytel/product-wall/helpers';
import { Alignable, Columns, ColumnsItem, Spacer, SpacerSize, Text, TextLevels, TypographyAlign, View } from '@bytel/trilogy-react-ts';
import { DiscountEnumType } from '@app-types/discount';
import { FundingType } from '@app-types/funding';
import { PriceFundingsDetails } from '@app-types/product';
import { Price } from '@components/price';
import { PriceDetailToday, PriceTotalToday } from '@components/product-details-modal';
import { isComptant, isEDP, isYounited } from '@helpers/funding';
import { getDiscountValue } from '@helpers/product';
import younitedIcon from '@assets/logo-younited.svg';
type FundingProps = {
  productDetails: PriceFundingsDetails;
  funding?: FundingType;
};
export function Funding({
  productDetails: {
    discounts,
    price
  },
  funding
}: FundingProps) {
  const sumODRDiscount = getDiscountValue(discounts, DiscountEnumType.ODR);
  if (!funding && sumODRDiscount > 0) {
    return <>
                <PriceDetailToday value={sumODRDiscount} />
                <PriceTotalToday value={price.final - sumODRDiscount} />
            </>;
  } else if (!funding) {
    return null;
  }
  return <>
            {!isComptant(funding.type) && <Columns mobile>
                    <ColumnsItem size={7} verticalCenter className='has-text-left'>
                        <Text>À régler aujourd&apos;hui</Text>
                    </ColumnsItem>
                    <ColumnsItem size={5} className='has-text-right'>
                        <Price align={Alignable.ALIGNED_END} amount={calculatePrice(funding.initialDeposit)} className='font-inherit' />
                    </ColumnsItem>
                </Columns>}
            {!isYounited(funding.type) && sumODRDiscount > 0 && <PriceDetailToday value={sumODRDiscount} />}
            {(isEDP(funding.type) || isYounited(funding.type)) && <Columns mobile>
                    <ColumnsItem size={6} verticalCenter className='has-text-left'>
                        <div>
                            <Text marginless>Puis à payer par mois</Text>
                            <Text level={TextLevels.THREE} typo={TypographyAlign.TEXT_LEFT} marginless>
                                {isEDP(funding.type) ? 'Facilité ' : 'Financement'} sur {funding.installmentCount} mois
                                {!isEDP(funding.type) ? '*' : ''}
                            </Text>
                        </div>
                    </ColumnsItem>
                    <ColumnsItem size={6} className='has-text-right'>
                        <Price align={Alignable.ALIGNED_END} amount={funding.monthlyAmount} period='mois' className='font-inherit' />
                    </ColumnsItem>
                </Columns>}
            {isYounited(funding.type) && sumODRDiscount > 0 && <PriceDetailToday value={sumODRDiscount} />}
            {sumODRDiscount > 0 && <PriceTotalToday value={isYounited(funding.type) ? price.final - sumODRDiscount + funding.interestAmount : price.final - sumODRDiscount} />}
            {isYounited(funding.type) && <>
                    <Spacer size={SpacerSize.MEDIUM} />
                    <View className='subtable has-text-left has-background-grey-lighter column card is-shadowless'>
                        <Text level={TextLevels.THREE} className='is-flex' marginless>
                            *Financement &nbsp; <Image src={younitedIcon} alt='Younited' width={70} />
                        </Text>
                        <Text level={TextLevels.THREE} marginless>
                            Intérêts : {funding.interestAmount}€ / Taux annuel effectif global (TAEG) fixe :{' '}
                            {funding.APR}%
                            {funding.fixedInterestRate && funding.fixedInterestRate >= 0 && <>&nbsp;/ Taux débiteur fixe :&nbsp;{funding.fixedInterestRate}%</>}
                        </Text>
                        <Text level={TextLevels.THREE}>
                            Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant
                            de vous engager.
                        </Text>
                    </View>
                </>}
        </>;
}