import { getProductStock } from '@services/products';

import {
    SapiMergedSapicType,
    SapiProductChildType,
    SapiProductChildTypeWithSapicInfo,
    SapiProductType,
} from '@app-types/api/sapi';
import {
    BaseProductFull,
    FeatureType,
    ProductChildFull,
    ProductFeatureType,
    ProductParentFull,
    ProductStockDetails,
    ProductType,
} from '@app-types/product';
import { StockType } from '@app-types/product-shipping';

import { roundStringNumber } from '@helpers/number';

import { stockConfig } from '@constants/stock';

import { getStockOptions, getStockStatus, isOutOfStock } from './stock';

export const getCurrentProductGencode = (products: ProductParentFull, urlProduct: string): string => {
    const childs = Object.entries(products.childs);

    // Return the product with the matching URL
    const productWithMatchingUrl = childs.find(([_gencode, product]) => product.urlKey === urlProduct)?.[0];

    if (productWithMatchingUrl) return productWithMatchingUrl;

    // Return the first product in stock
    const productInStock = childs.find(([_gencode, product]) => !isOutOfStock(product.stockDetails.stock.status))?.[0];

    if (productInStock) return productInStock;

    // Return the first product
    return childs[0]![0];
};

export function transformChildProductToParentProduct(childProduct: SapiProductChildType): SapiProductType {
    return {
        ...childProduct,
        childs: {
            [childProduct.gencode]: childProduct,
        },
        product_ids: [childProduct.gencode],
        parentGencode: childProduct.gencode,
    };
}

function getConnectionRange(connection: string | undefined) {
    if (connection) {
        return `${connection.toUpperCase()}${connection === '5g' ? '/4G+' : ''}`;
    }
    return undefined;
}

export const sapiProductMapper = async (
    sapiProduct: SapiMergedSapicType,
    userZipCode?: string,
): Promise<ProductParentFull> => {
    try {
        const featuresRules: Record<string, string[]> = {};

        Object.values(sapiProduct.childs).forEach((child) => {
            if (child.color && child.capacity) {
                if (!featuresRules[child.color]) {
                    featuresRules[child.color] = [];
                }

                if (!featuresRules[child.color]?.includes(child.capacity)) {
                    featuresRules[child.color]?.push(child.capacity);
                }
            }
        });

        const recovery = sapiProduct.bonus_recovery
            ? {
                  bonus: +sapiProduct.bonus_recovery,
                  dates: {
                      start: sapiProduct.recovery_from_date?.replace(' ', 'T') ?? '',
                      end: sapiProduct.recovery_to_date?.replace(' ', 'T') ?? '',
                  },
              }
            : undefined;

        return {
            ...mapCommonAttributes(sapiProduct),
            productIds: sapiProduct.product_ids,
            childs: await getChilds(sapiProduct, userZipCode),
            features: {
                color: getFeatures(sapiProduct.childs, ProductFeatureType.COLOR, 'color_code'),
                capacity: getFeatures(sapiProduct.childs, ProductFeatureType.CAPACITY, 'capacity'),
            },
            featuresRules,
            recovery,
        };
    } catch (error) {
        console.error('Could not map SAPI product', error);
        throw new Error(`Could not map SAPI product`);
    }
};

const mapCommonAttributes = (
    product: SapiMergedSapicType | SapiProductChildTypeWithSapicInfo,
    isChild?: boolean,
): BaseProductFull => {
    const odr = product.odr && isChild ? parseInt(product.odr.amount) : 0;

    const prices = isChild
        ? {
              initial: product.price,
              withOdr: product.price - odr,
              odr,
          }
        : {
              initial: product.price,
          };

    const sapiInfo = {
        name: product.name,
        image: product.image,
        hasFunding: false,
        availableCnc: product.available_click_and_collect || false,
        recommended: false,
    };

    const mappedSapicAttributes = product.productSapic!;

    return {
        ...sapiInfo, // this is overcharged by mappedSapicAttributes if product.productSapic exists
        ...mappedSapicAttributes,
        gencode: product.gencode,
        campaignId: product.campaign_id,
        das: getDas(product),
        ecoTax: product.ecotax && product.ecotax !== '0' ? roundStringNumber(product.ecotax, 2) : undefined,
        entityId: product.entity_id,
        manufacturer: product.manufacturer,
        manufacturerCode: product.manufacturer_code,
        medias: product.medias,
        metaDescription: product.meta_description ?? '',
        packContent: product.pack_content,
        price: mappedSapicAttributes.price,
        prices,
        reparability: mapReparapility(product),
        sku: product.sku,
        title: product.title ?? `${product.manufacturer} ${product.name}`,
        type: getProductType(product.type_id as ProductType),
        urlKey: product.url_key,
        videoUrl: product.url_video,
    };
};

const mapReparapility = (product: Partial<SapiMergedSapicType>) => ({
    note: product.note_reparation ? parseFloat(product.note_reparation) : undefined,
    pdf: product.note_reparation_pdf,
});

const mapCharacteristics = (product: SapiMergedSapicType, productChild: SapiProductChildTypeWithSapicInfo) => ({
    weight: roundStringNumber(product.weight, 3),
    length: roundStringNumber(product.length, 3),
    width: roundStringNumber(product.width, 3),
    depth: roundStringNumber(product.depth, 3),
    height: roundStringNumber(product.height, 3),
    diagonal: roundStringNumber(product.diagonal, 3),
    battery: {
        capacity: roundStringNumber(product.battery_capacity, 3),
        isRemovable: product.battery_removable,
        talkTime: product.battery_talk_time,
        standbyTime: product.battery_standby_time,
    },
    screenTechnology: product.screen_technology,
    material: product.material,
    resolution: {
        width: roundStringNumber(product.resolution_width, 0),
        height: roundStringNumber(product.resolution_height, 0),
        sensor: roundStringNumber(product.resolution_sensor, 0),
    },
    os: {
        name: product.os,
        version: product.os_version,
    },
    wifi: product.wifi,
    connectionRange: getConnectionRange(product.max_connection),
    isSimMultiple: product.sim_multiple,
    isESimCompatible: ['esim', 'hybride'].includes(product.sim_type ?? ''),
    hasAutoFocus: product.auto_focus,
    isDoubleCamera: product.double_camera,
    capacityAvailable: product.capacity_available,
    capacitySd: product.capacity_sd,
    processor: {
        core: product.processor_core,
        speed: product.processor_speed,
    },
    included: productChild.included,
    nonIncluded: productChild.non_included,
    charging:
        product?.charging_head !== undefined
            ? {
                  head: product.charging_head,
                  maxLoadPower: product.max_load_power ? parseFloat(product.max_load_power).toString() : undefined,
                  minLoadPower: product.min_load_power ? parseFloat(product.min_load_power).toString() : undefined,
              }
            : undefined,
    output: {
        memoryCard: productChild.memory_card_output,
        charger: productChild.charger_output,
        audio: productChild.audio_output,
    },
});

const getProductType = (type: ProductType): ProductType => {
    switch (type) {
        case ProductType.ACCESSORY:
        case ProductType.ACCESSORY_CONFIGURABLE:
            return ProductType.ACCESSORY;
        case ProductType.PHONE:
        case ProductType.PHONE_SIMPLE:
            return ProductType.PHONE;
        default:
            return type;
    }
};

const getDas = (product: Partial<SapiMergedSapicType>): { name: string; value: string }[] => {
    const das: { name: string; value: string }[] = [];

    const sar = roundStringNumber(product.sar, 3, true);
    if (sar) {
        das.push({ name: 'tête', value: sar });
    }

    const trunkSar = roundStringNumber(product.trunk_sar, 3, true);
    if (trunkSar) {
        das.push({ name: 'tronc', value: trunkSar });
    }

    const limbsSar = roundStringNumber(product.limbs_sar, 3, true);
    if (limbsSar) {
        das.push({ name: 'membres', value: limbsSar });
    }

    return das;
};

const getFeatures = (
    childs: SapiProductType['childs'],
    feature: ProductFeatureType,
    value: keyof SapiProductChildType,
): FeatureType[] => {
    const featuresMap = new Map<string, string>();

    Object.values(childs).forEach((child) => {
        const featureName = child[feature];
        const featureValue = child[value];

        if (featureName && !featuresMap.has(featureName) && featureValue && typeof featureValue === 'string') {
            featuresMap.set(featureName, featureValue);
        }
    });

    if (feature === ProductFeatureType.CAPACITY) {
        return sortCapacities(Array.from(featuresMap.values())).map((value) => ({ name: value, value }));
    }

    return Array.from(featuresMap, ([name, value]) => ({ name, value }));
};

async function getProductsResa(gencodeList: string[]): Promise<{ [key: string]: StockType }> {
    const productsStock: { [key: string]: StockType } = {};

    const results = await Promise.all(gencodeList.map((gencode) => getProductStock(gencode)));

    results.forEach((result, index) => {
        productsStock[gencodeList[index]!] = {
            isEresa: result.isEresa,
            isPreresa: result.isPreresa,
        };
    });

    return productsStock;
}

export async function getChilds(
    product: SapiMergedSapicType,
    userZipCode?: string,
): Promise<Record<string, ProductChildFull>> {
    // While smart traffik not ok keep false for withEresa param
    const childsStock = await getChildsStock(product.product_ids, product.childs, userZipCode, false);

    return Object.values(product.childs).reduce(
        (accum, child) => {
            accum[child.gencode] = {
                ...mapCommonAttributes(child, true),
                characteristics: mapCharacteristics(product, child),
                colorCode: child.color_code,
                color: child.color,
                capacity: child.capacity,
                parentTitle: product.title,
                stockDetails: childsStock[child.gencode]!,
                isOnlyWeb: product.exclu_web,
                quantity: child.qty,
            };
            return accum;
        },
        {} as Record<string, ProductChildFull>,
    );
}

export async function getChildsStock(
    gencodeList: string[],
    childs: SapiMergedSapicType['childs'],
    userZipCode?: string,
    withEresa = true,
): Promise<Record<string, ProductChildFull['stockDetails']>> {
    const productsResa = withEresa ? await getProductsResa(gencodeList) : undefined;

    return Object.entries(childs).reduce(
        async (accumPromise, [gencode, child]) => {
            const accum = await accumPromise;
            const productStock = productsResa?.[gencode] ?? { isEresa: false, isPreresa: false };
            const isEresa = !child.exclu_web && productStock.isEresa;
            const stock =
                stockConfig[
                    getStockStatus(
                        isEresa,
                        child.is_preorder,
                        child.in_stock,
                        child.available_soon,
                        child.soon_exhausted,
                    )
                ];
            const stockOptions = await getStockOptions(stock, child, productStock, userZipCode);
            accum[gencode] = {
                ...stockOptions,
                stock,
            };
            return accum;
        },
        Promise.resolve({} as Record<string, ProductChildFull['stockDetails']>),
    );
}

export async function getProductsStock(
    products: ProductChildFull[],
    userZipCode?: string,
    withEresa = true,
): Promise<{ [key: string]: ProductStockDetails }> {
    const gencodeList = products.map((product) => product.gencode);
    const productsResa = withEresa ? await getProductsResa(gencodeList) : undefined;

    const stockDetails: { [key: string]: ProductStockDetails } = {};
    for (const product of products) {
        const productStock = productsResa?.[product.gencode] ?? { isEresa: false, isPreresa: false };
        const isEresa = !product.isOnlyWeb && productStock.isEresa;

        const { isPreorder, isInStock, isAvailableSoon, isSoonExhausted } = product.stockDetails;
        const stock = stockConfig[getStockStatus(isEresa, isPreorder, isInStock, isAvailableSoon, isSoonExhausted)];
        const stockOptions = await getStockOptions(stock, product, productStock, userZipCode);
        stockDetails[product.gencode] = {
            ...stockOptions,
            stock,
        } as ProductStockDetails;
    }

    return stockDetails;
}

function sortCapacities(capacities: string[]): string[] {
    return capacities.sort((a, b) => {
        const regex = /(\d+)\s*(Go|To|Mo)/;
        const matchA = a.match(regex);
        const matchB = b.match(regex);

        const convertToMo = (match: RegExpMatchArray | null) => {
            if (!match) return 0;
            const value = parseInt(match[1] as string);
            switch (match[2]) {
                case 'To':
                    return value * 1024 * 1024;
                case 'Go':
                    return value * 1024;
                case 'Mo':
                    return value;
                default:
                    return 0;
            }
        };

        return convertToMo(matchA) - convertToMo(matchB);
    });
}
